.nuevoReactivoForm {
  margin: 0 10px;
  height: 90% !important;
}

.nuevoReactivoForm label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.nuevoReactivoForm input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 26px 5px 10px;
  border-radius: 15px;
  outline: none;
}

.nuevoReactivoForm textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.nuevoReactivoForm input::placeholder {
  font-family: 'Roboto';
}
.editarReactivoForm {
  margin: 0 10px;
  height: 90% !important;
}

.editarReactivoForm label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.editarReactivoForm input {
  background-color: #ffffff;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.editarReactivoForm textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.editarReactivoForm input::placeholder {
  font-family: 'Roboto';
}
#caja-pregunta {
  margin-bottom: 30px;
  margin-top: 30px;
}
.input-pregunta {
  background-color: white !important ;
  border: 0px solid #ccc !important  ;
  border-bottom: 1px solid #ccc !important   ;
  width: 100% !important ;
  box-sizing: border-box !important  ;
  padding: 5px 18px !important   ;
  border-radius: 0px !important  ;
  text-align: center !important  ;
  font-size: 20px !important  ;
  outline: none !important   ;
  height:none !important
}
.caja-opcion {
  margin-top: 10px;
}
.botones-radio {
  height: 20px !important;
}
.tag-valor {
  font-size: 16px !important;
}
.contenedor-respuestas {
  overflow-x: auto;
  height: 230px;
}
.caja-respuesta-corta {
  margin-top: 30px;
}
.button-delete-option {
  border-radius: 13px;
  border-color: #9f9fa2;
  border: solid;
  background-color: black;
  color: white;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

.caja-etiquetas {
  margin-top: 30px;
}
.caja-etiquetas input {
  margin-top: 0px !important;
}

.select-search-box > div > div > div > svg {
  display: none;
}
.select-search-box > div > div > span {
  display: none;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.select-search-box__option {
  padding-left: 0px;
  padding-right: 0px;
}
