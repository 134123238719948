.view-title {
    width: 100%;
    height: 26px;
    background-color: #7E7D82;
    color: #fff;
    margin: 0;
    padding: 6px 20px;
    margin: 6px 30px;
    font-size: 1.3em;
    border-radius: 50px;
}