.nuevoDimensionForm {
    margin: 0 10px;
    height: 95% !important;
}


.nuevoDimensionForm>div>div{
    margin-top: 25px;
}

.nuevoDimensionForm label {
    font-size: 13px;
    font-weight: 600;
    display: block;
    margin: 5px 15px;
}

.nuevoDimensionForm input {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 18px;
    border-radius: 15px;
    outline: none;
}

.nuevoDimensionForm select {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 0px;
    border-radius: 15px;
    outline: none;
}

.nuevoDimensionForm textarea {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    height: 78px;
    box-sizing: border-box;
    padding: 5px 18px;
    border-radius: 15px;
    outline: none;
}

.nuevoDimensionForm input::placeholder,textarea::placeholder {
    font-family: 'Roboto';
}

.examinar-imagen button, .descripcion-dimension{
    width:50%;
    border:none;
    outline:none;
    cursor:pointer;
    font-size:20px;
    border-radius:30px;
    background-color:#1B1C20 !important;
    color:white !important
}
.examinar-imagen button:hover,.descripcion-dimension:hover{
    background-color:grey !important
}
.nuevoDimensionForm>div>div{
    margin-top:10px;
}
.inputs-tabla-escala{
    margin-top:-5px;
    margin-bottom:-5px;
}
.contenedor-escala100{
    border:solid !important;
    border-width:1px !important;
    border-radius:20px !important;
    height:60px;
    padding-left:20px;
    border-color:grey !important;
    margin-bottom:5px;
    position:relative !important;
}
.cuestionarios-container{
    height:300px;
}
.contenedor-rangos-escala100,.contenedor-rangos-escala100-instrumento{
    overflow:auto;
    width:98%;
    margin:auto;
    border:solid;
    border-width:1px;
    height:300px !important;
}

.contenedor-rangos-escala100::-webkit-scrollbar,.contenedor-rangos-escala100-instrumento::-webkit-scrollbar{
    width: 5px;
    height: 5px !important;
    background-color: rgba(255,255,255,0.2);
}

.contenedor-rangos-escala100::-webkit-scrollbar-thumb,.contenedor-rangos-escala100-instrumento::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.6)
}


.add-escala100-button{
    text-align:center;
    outline:none;
    cursor:pointer;
    background-color:green;
    border:none;
    font-size:16px;
    border-radius:10px;
    color:white;
}
.nuevoDimensionForm th{
    z-index:0 !important
}

.nuevoEtiquetaForm textarea {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    height: 78px;
    box-sizing: border-box;
    padding: 5px 18px;
    border-radius: 15px;
    outline: none;
}

.nuevoEtiquetaForm input::placeholder,textarea::placeholder {
    font-family: 'Roboto';
}
.contenedor-estructura-booleana div{
    height:30px !important;
}
.contenedor-estructura-booleana{
    border:solid;
    border-width:1px;
    border-color:grey
}

.error{
    color:red !important
}
.eliminar-escala{
    position:absolute;
    background-color:white !important;
    border:none;
    outline:none;
    padding:0px;
    cursor:pointer;
    right:0px;
    top:19px;
    height:24px;
}
.eliminar-escala5{
    background-color:white !important;
    border:none;
    outline:none;
    padding:0px;
    cursor:pointer;
    height:24px;
}
.eliminar-escala5:hover{
    background-color:#cfcfcf !important;
}