.progreso-estructuras2 {
  width: calc(100% - 880px) !important;
  position: absolute !important;
  top: 125px !important;
  left: 681px !important; 
}
.progreso-estructuras2 p {
  margin-bottom: 0px;
}
.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}
.contenedor-reporte-creado-estructuras2 {
  margin-top: 95px !important;
  height: calc(100% - 100px);
}
.contenedor-reporte-creado-estructuras2 th {
  min-width: 300px;
}
.contenedor-reporte-creado-estructuras2 .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}
.search-creado-estrucutras2 {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 98px;
  height: 30px;
  border: 2px solid #808080;
  border-radius: 13px;
}
.search-creado-estrucutras2::placeholder {
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.search-creado-estrucutras2::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.search-creado-estrucutras2::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}
.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}
.circle-loading {
  width: 200px !important;
}

.small-bar {
  width: 110px;
}
