.opciones-bar {
  height: 60px !important;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.selector-fecha {
  width: 100%;
  height: 25px;
}

.selector-fecha > div {
  height: inherit;
}

.selector-fecha > div::before {
  content: none;
}

.selector-fecha > div::after {
  content: none;
}

.selector-fecha > div > input {
  height: inherit;
}

/* .nuevoEtiquetaForm__calendario {
  overflow-x: auto;
} */

.SearchInput-Calendario {
  position: absolute;
  right: 0px;
  margin-top: -30px;
  border: 2px solid #808080;
  border-radius: 13px;
  padding-left: 5px;
  outline: none;
}

.SearchInput-Calendario::placeholder {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.SearchInput-Calendario::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.SearchInput-Calendario::-ms-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
