.table-dash thead tr th {
    background-color: #1B1C20;
    color: #fff;
    font-size: 1.25em;
    padding: 5px 10px;
    border-left: 2px solid #fff;
}

.table-dash tbody tr td {
    padding: 5px 10px;
    border-left: 2px solid #fff;
}

.table-dash tbody tr:nth-child(even) {
    background-color: #eeeeef;
}
.ultimaFila{
    background-color: #aeaeae;
}
.ultimaFila td{
    font-size: 18px;
    font-weight: 4000
}


.HeadArrows {
    position: absolute;
    top: 0;
    right: 0;
}

.HeadArrows svg {
    display: block;
}

.HeadArrows svg:nth-child(2) {
    margin-top: -15px;
}

.HeadCell {
    cursor: pointer;

}

.contenedor-buttons {
    text-align: center;
}

.contenedor-buttons button {
    border-top: #1a2f36 solid 2px;
    border-bottom: #1a2f36 solid 2px;
    border-left: #1a2f36 solid 2px;
    border-right: none;
    background-color: white;
    height: 40px;
    color: #1a2f36;
    padding: 0 10px;
    cursor: pointer;
    transition: all ease .5s;
    margin: 0;
}

.contenedor-buttons button:focus {
    outline: 0;
}

.contenedor-buttons button:hover {
    background-color: #1a2f36;
    color: white
}

.contenedor-buttons button:last-child {
    border-right: #1a2f36 solid 2px;
    border-radius: 0 10px 10px 0;
    padding: 0 15px;
}

.contenedor-buttons button:first-child {
    border-radius: 10px 0 0 10px;
    padding: 0 15px;
}

.contenedor-buttons button.active {
    background-color: #1a2f36;
    color: white;
}

th,td {
    text-align: center !important;
}
.doble-tabla tr{
    height: 70px;
}
.header-edit-guardar{
    width:642px;
    margin-left:-1px !important;
    background-color:#1B1C20;
    margin-left:2px;
    color:white;
    height:35px;
    border-left:solid;
    border-right:solid;
    border-right-width:2px;
    border-top:solid;
    border-color:green;
}
.header-edit-guardar button{
    margin:10px;
    border:none;
    outline:none;
    border-radius:10px;
    padding:3px 6px 3px 6px;
    cursor:pointer;
    font-weight:600 !important;
}

.input-coordinador-draggable{
    border:none;
    margin:0px;
    width:99%;
    height:25px !important;
    text-align:center;
    background-color:rgba(0,0,0,0);
    outline:none;
    font-size:14px;
    color:black !important
}
.contenedor-opciones{
    display:none;
    background-color:white;
    border:solid;
    border-width:1px;
    border-color:grey;
    border-radius:10px;
    width:320px;
    max-height:300px;
    position:absolute;
    overflow:auto;
}

.contenedor-opciones::-webkit-scrollbar{
    width: 5px;
    height: 5px !important;
    background-color: rgba(255,255,255,0.2);
}

.contenedor-opciones::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.6)
}

.td-coordinador-draggable{
    padding-top:0px !important;
    padding-left:0px !important;
    padding-right:0px !important;
    padding-bottom:0px !important;
    border-left:solid !important;
    border-left-width:2px !important;
    border-left-color:green !important;
}
.td-coordinador-draggable-derecho{
    border-right:solid !important;
    border-right-width:2px !important;
    border-right-color:green !important;
}

.option-select-draggable{
    margin:0px;
    font-size:20px;
    cursor:pointer;
}
.option-select-draggable:hover{
    background-color:grey;
}
.left-header{
    border-left:solid !important;
    border-left-width:2px !important;
    border-left-color:black !important;
    border-left-color:green !important;
}
.right-header{
    border-right:solid !important;
    border-right-width:2px !important;
    border-right-color:black !important;
    border-right-color:green !important;
}
.selected-row{
    border-top:solid;
    border-bottom:solid !important;
    border-width:2px !important;
    border-color:green !important
}
.cuadrito-selector{
    width:10px;
    height:10px;
    background-color:green;
    position:absolute;
    right:0px;
    bottom:0px;
}