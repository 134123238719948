.tabs-list {
  display: flex;
}
.NewModal-container > .tabs-list{
  
  margin-top: 10px;
}
.tab {
  border-radius: 20px 20px 0 0;
  padding: 5px 20px;
  cursor: pointer;
  transition: all ease 0.2s;
}

.tab:hover {
  z-index: 99;
}

.tab:nth-child(odd) {
  background-color: #b3b3b3;
}

.tab:nth-child(even){
  background-color: #1b1c20;
  color: #fff;
  margin-left: -10px;
}

.tab.active {
  background-color: #e72400;
  z-index: 9;
  color: white;
}

.tab-content {
  height: calc(100% - 30px);
  border-left: 1px solid #b3b3b3;
  border-right: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  padding: 0;
}
.tab-content-modal{
  height: calc(100% - 75px);
  border-left: 1px solid #b3b3b3;
  border-right: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  padding: 0;
}

.tab-header {
  height: 15px;
}
