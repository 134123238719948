.Actualizacion-Profesional {
  text-decoration: underline;
  color: grey;
  font-size: 20px;
  cursor: pointer;
}
.estatus-actualizacion {
  color: red;
}
.registro-materia-resultados {
  padding: 0px;
  color: #333;
  font-weight: 600;
  cursor: pointer;
}
.registro-materia-resultados span {
  margin-left: 10px;
}
.registro-materia-resultados:hover {
  color: #000;
  font-weight: 1200;
  text-decoration: underline;
}
.contenedor-info-detalle-asignacion {
  background-color: #efefef;
  width: 100%;
  height: 100px;
}
.contenedor-info-detalle-asignacion div {
  margin: 0px;
}
.contenedor-info-detalle-asignacion p {
  margin: 0px;
  margin-left: 10px;
  margin-top: 4px;
  font-weight: 900;
}
.contenedor-info-detalle-asignacion p span {
  color: #444;
}
.contenedor-header-detalle-asignacion span {
  margin-left: 30px;
  border: solid;
  padding-left: 10px;
  padding-right: 10px;
  border-width: 1px;
  border-color: grey;
}
.contenedor-reporte-detalle-asignacion {
  width: 100%;
  height: calc(70% - 80px);
  overflow: auto !important;
}

.contenedor-reporte-detalle-integral {
  width: 100%;
  height: calc(70% - 120px);
  overflow: auto !important;
}

.contenedor-reporte-detalle-asignacion::-webkit-scrollbar,
.contenedor-reporte-detalle-integral::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}
.contenedor-reporte-detalle-asignacion::-webkit-scrollbar-thumb,
.contenedor-reporte-detalle-integral::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.contenedor-header-detalle-integral {
  width: calc(100% - 5px) !important;
  font-weight: 900;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contenedor-header-detalle-integral .vertical-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  float: right;
}
.contenedor-header-detalle-integral p {
  background-color: #eeeeef;
}
.contenedor-header-detalle-integral
  .contenedor-numero-registro:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.5);
}
.contenedor-titulo-registro {
  position: relative;
  float: right;
  margin-right: 0px !important;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: -5px !important;
  margin-bottom: 0px !important;
  padding: 5px;
}
.contenedor-headers-detalle-integral-p {
  margin-bottom: 40px !important;
  color: white !important;
  background-color: white !important;
}
.contenedor-headers-detalle-integral-p span {
  color: black !important;
  background-color: white !important;
  text-align: left;
}
.contenedor-reporte-actualizacion-profesional {
  height: calc(100% - 200px);
}
.contenedor-header-actualizacion-profesional {
  height: 40px;
}
.contenedor-reporte-actualizacion-profesional div::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.contenedor-reporte-actualizacion-profesional div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}

.progress-bar {
  border: 1px solid gray;
  height: 15px;
  width: 300px;
  border-radius: 8px;
}

.progress {
  border-radius: 8px;
  background-image: linear-gradient(to right, #98192c, red);
  height: 100%;
}

.percent {
  color: grey;
  margin-left: 7px;
}

.section-title-container {
  height: 35px;
  background-color: #eee;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.section-title {
  font-size: 22px;
  margin-left: 15px;
}

.button-save {
  border: none;
  background-color: #636363;
  border-radius: 10px;
  padding: 1px 11px;
  color: #fff;
  cursor: pointer;
}

.input-busqueda {
  margin: 0px 25px 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.item-tabla {
  padding: 4px;
  font-weight: 600;
  text-align: left !important;
  padding-left: 15px;
}
.progress-bar-container{
  width:350px !important
}