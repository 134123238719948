aside {
  background-color: #1b1c20;
  width: 220px;
  height: calc(100vh - 110px);
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
}

aside::-webkit-scrollbar {
  width: 5px;
  height: 10px !important;
  background-color: rgba(255, 255, 255, 0.8);
}

aside::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

aside ul {
  padding: 0;
  margin: 0;
}

aside ul li a {
  font-size: 1.2em;
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px 0 10px 20px;
  transition: all ease 0.5s;
  padding-left: 50px;
}

aside ul li a svg {
  width: 20px;
  margin-right: 10px;
  position: absolute;
  left: 20px;
}

aside ul li:hover > a {
  background-color: #c6c6c5;
  color: #1b1c20;
  border-left: 5px solid #e72400;
}

aside ul li:hover > a svg path {
  fill: black;
}

aside ul li.active > a {
  background-color: #c6c6c5;
  color: #1b1c20;
  border-left: 5px solid #e72400;
}

aside ul li.active > a svg path {
  fill: black;
}

aside ul .menu-title {
  font-size: 1.75em;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 0 15px 20px;
}

aside ul .menu-title svg {
  width: 25px;
  margin-right: 10px;
}

.reportes-tab {
  background-color: rgb(0, 0, 0);
  width: 220px;
  right: 0;
  z-index: 999;
  -webkit-box-shadow: 0px 0px 22px -11px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 0px 22px -11px rgba(0, 0, 0, 0.42);
  box-shadow: 0px 0px 22px -11px rgba(0, 0, 0, 0.42);
}

.reportes-tab a {
  font-size: 16px;
  display: block;
  padding: 6px 8px;
  margin-left: 20px;
}

.reportes-tab a:hover {
  background-color: #333;
  border-left: 5px solid #e72400;
}

.reportes-tab a.active {
  background-color: #333;
  border-left: 5px solid #e72400;
}
