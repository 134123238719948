.contenedor-reporte-estructura {
  display: inline-block;
  height: calc(100% - 60px);
  width: 75%;
}
.contenedor-reporte-estructura th {
  min-width: 300px;
}

.aside-estructura {
  display: inline-block;
  height: calc(100% - 20px);
  width: 25%;
  position: relative;
}

.aside-title {
  margin-top: 5px;
  position: absolute;
  background: linear-gradient(to right, #c50200, red);
  color: white;
  width: 98%;
  font-size: 25px;
  text-align: center;
  border-radius: 30px;
  right: -15px;
  padding: 5px;
}
.sub-aside {
  position: absolute;
  margin-top: 50px;
  text-align: center;
  width: 100%;
}
.title-sub-aside {
  text-align: center;
  width: 100%;
  font-size: 20px;
  margin: 0px;
  margin-top: 20px;
}
.alert-aside {
  position: absolute;
  left: 10px;
  margin-top: -4px;
}
.aside-estructura p {
  margin: 7px;
}
.ver-mas-aside {
  background: linear-gradient(to right, #c50200, red);
  color: white;
  outline: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 3px;
}
.SearchInput::placeholder {
  padding-left: 35% !important;
}

.SearchInput-participacion {
  position: absolute !important;
  width: 200px !important;
  right: 27%;
  margin-top: -35px;
  border: 2px solid #808080;
  border-radius: 13px;
  height: 30px;
}

.SearchInput-participacion::placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-participacion::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-participacion::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}

.contenedor-pickers-filtro {
  position: absolute;
}
.contenedor-pickers-filtro input {
  border: solid;
  border-width: 1px;
  border-radius: 30px;
  border-color: grey;
  margin-left: 5px;
  margin-right: 5px;
}
.contenedor-pickers-filtro span {
  position: relative;
  top: 7px;
}
.contenedor-filtro-despliegue {
  margin-top: 5px;
  margin-bottom: 5px;
}
.SearchInput-tabla-completa {
  margin-right: 40px;
  padding-left: 5px;
  outline: none;
}
.opciones-bar {
  height: 50px;
}
.filtros-tabla-completa {
  position: absolute;
  top: 30px;
  left: 40px;
}
.filtros-tabla-completa p {
  margin-bottom: 0px;
}
.select-periodo {
  width: 200px;
}
thead,
th {
  z-index: 0 !important;
}
.SearchInput {
  height: 30px;
}
.filtros-tabla-completa .sub-filtro {
  display: inline-block;
  margin-left: 10px;
}
.sub-filtro .principal-input-multiselect {
  height: 33px;
  margin-top: 0px;
  border: solid;
  border-color: #bbb;
  border-radius: 5px;
  border-width: 1px;
  width: 275px;
  outline: none;
}
.contenedor-aceptar-opciones input {
  width: 80%;
}
.contenedor-loader-tabs-tabla {
  position: absolute;
  left: 35%;
}
th {
  z-index: 1 !important;
}

.selects-tabla-participacion-academica {
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
  text-align: center !important;
  cursor: pointer;
}

.editar-catalogo-from {
  margin: 0 10px;
}

.editar-catalogo-from > div > div {
  margin-top: 25px;
}

.editar-catalogo-from label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.editar-catalogo-from input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
  margin-bottom: 10px;
}

.editar-catalogo-from textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.editar-catalogo-from input::placeholder,
textarea::placeholder {
  font-family: 'Roboto';
}

.contenedor-opciones-descripcion-catalogo {
  height: 140px;
  overflow: auto;
  max-height: 240px;
  text-align: center;
  border: solid;
  border-width: 1px;
  border-color: #aaa;
  margin-bottom: -10px;
  padding: 10px;
  border-radius: 10px;
}

.contenedor-opciones-descripcion-catalogo button {
  border-radius: 10px;
  border: none;
  background-color: green;
  color: white;
  cursor: pointer;
  padding: 3px 6px 3px 6px;
  outline: none;
}
.contenedor-opciones-descripcion-catalogo button:disabled {
  background-color: grey;
  cursor: unset;
}
.contenedor-una-opcion {
  width: 98%;
  position: relative !important;
}
.eliminar-opcion-descripcion {
  position: absolute;
  right: -3px;
  top: -3px;
  background-color: white !important;
  color: black !important;
  padding: 0px !important;
}
.eliminar-opcion-descripcion:disabled {
  color: grey !important;
  cursor: unset;
}

.contenedor-reporte-estructura th {
  text-transform: capitalize;
}

.search-button {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
}
