
.contenedor-aceptar-opciones2{
	width: 30% !important;
	max-width: 320px !important;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 3;
	max-height: 330px !important;
	text-align: center;
	position: absolute;
	width:320px !important;
}

.div-contenedor-opciones2{
	background-color: white;
	z-index: 3;
	max-height: 170px !important;
	overflow-y: auto;
	text-align: center;
}
.contenedor-aceptar-opciones4 .div-contenedor-opciones2{
	text-align: left;
	font-size: 15px;
	padding-left: 3px;
}
.div-contenedor-opciones2::-webkit-scrollbar{
    width: 5px;
    height: 5px !important;
    background-color: rgba(255,255,255,0.2);
}

.div-contenedor-opciones2::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.6)
}

.div-contenedor-opciones2 p{
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 5px;
	padding-bottom: 5px;
	cursor: pointer;
}

.div-contenedor-opciones2 p input{
	float: right;
	width: 30px;
}
.div-contenedor-opciones2 p:hover{
	background: #7E7D82
}

.div-contenedor-opciones2 .selected{
	background: #7E7D82
}


.principal-input-multiselect{
	text-align: center !important;
	cursor: default;
}

.arrow-down-select svg{
	position:absolute;
	margin-left: -40px;
	margin-top: -7px;
	font-size: 45px;
	color:#505050;
	pointer-events: none;
}

.contenedor-multiselect{
	position: relative;
}
#span-total-materias{
	position: absolute;
	left: 10px;
	top: 5px;
	font-size: 14px;
}
.principal-input-multiselect2{
	width: 96% !important;
	text-align: center;
	cursor: default;
}