
.franja-titulo{
    writing-mode: vertical-lr;
    transform: rotate(180deg);
	color:white;
	background:linear-gradient(to right, #c50200, red);
	text-align:center;

	border-radius:0px 30px 30px 0px;
}
.cotenedor-franja{
	position:relative;
	margin-bottom:30px;
}
.contenedor-registros-franja p:nth-child(even) {
    background-color: #eeeeef;
}
.contenedor-registros-franja p{
	margin:0px;
	padding:5px;
	margin-left:10px;
	position:relative;
	padding-right:0px;
}
.contenedor-numero-registro{
	position:relative;
	float:right;
	margin-right:0px !important;
	background-color:rgba(0,0,0,0.1);
	margin-top:-5px !important;
	margin-bottom:0px !important;
	padding:5px;
}
.contenedor-numero-registro:nth-child(even){
	background-color:rgba(255,255,255,.5)
}