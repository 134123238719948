.nuevoEtiquetaForm {
  margin: 0 10px;
}

.nuevoEtiquetaForm > div > div {
  margin-top: 25px;
}

.nuevoEtiquetaForm label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.nuevoEtiquetaForm input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.descripcion-dimension-textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  height: 78px;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.descripcion-dimension-textarea::placeholder {
  font-family: 'Roboto';
}
.nuevoDimensionForm {
  overflow-y: auto;
  overflow-x: hidden;
}
