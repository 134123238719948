.contenedor-nuevo-evaluacion-integral .input-evaluacion {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}
.contenedor-nuevo-evaluacion-integral > div {
  height: 100%;
}
.contenedor-nuevo-evaluacion-integral label {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 5px;
}

.contenedor-agrupadores-dimensiones {
  height: calc(100% - 120px);
  overflow: auto;
  text-align: center !important;
  border: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: grey;
}
.agrupador-dimension {
  height: 230px;
  width: 95% !important;
  margin: auto;
  margin-top: 10px;
  border: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: grey;
}

.agrupador-dimension h6 {
  background-color: black;
  color: white;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.contenedor-dimensiones {
  margin-top: -4px;
  height: 180px !important;
  overflow: auto;
}
.dimension-registro {
  height: 55px !important;
  width: 95%;
  margin: auto;
  border: solid;
  border-width: 1px;
  border-top: none;
}
.dimension-derecha {
  margin-bottom: 10px;
  border-radius: 10px;
}
.dimension-derecha div:first-child,
.dimension-derecha div:last-child {
  padding-top: 15px;
}

.dimension-derecha {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.dimension-derecha:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.Común-class {
  background-color: rgb(246, 190, 152);
  border-radius: 10px;
  color: white;
  font-weight: 900;
}
.Común-class {
  background-color: rgb(246, 190, 152);
  border-radius: 10px;
  color: white;
  font-weight: 900;
}
.Específica-class {
  background-color: rgb(161, 184, 225);
  border-radius: 10px;
  color: white;
  font-weight: 900;
}
.Extra-class {
  background-color: rgb(210, 210, 210);
  border-radius: 10px;
  color: white;
  font-weight: 900;
}
.Reconocimiento-class {
  background-color: rgb(210, 210, 210);
  border-radius: 10px;
  color: white;
  font-weight: 900;
}
.contenedor-Selectores-Reactivos-dimensiones,
.contenedor-Agrupadores2 {
  height: calc(100% - 50px) !important;
}
.NewModal {
  position: relative;
}
.tabla-preguntas-instrumento-derecha {
  overflow: auto !important;
}

.lista-dimensiones {
  height: 100%;
  overflow-x: auto;
}
.lista-dimensiones > div {
  text-align: center;
  min-height: 50px;
  border: solid;
  border-width: 1px;
  width: 90%;
  margin-left: 5%;
  display: flex;
}
