#contenedor-NuevoInstrumento {
  height: calc(100% - 40px);
  margin: 0 10px;
  margin-top: 10px;
}
.NewModal-Instrumento {
  background-color: #fff;
  outline: 0;
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
}
.NewModal-Instrumento {
  background-color: #fff;
  outline: 0;
  max-width: 1250px;
  width: 90%;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
}

.NewModal-Instrumento h3 {
  margin: 0;
  background-color: #1b1c20;
  color: #fff;
  padding: 10px 25px;
  font-size: 2em;
  font-weight: 400;
  border-bottom: 10px solid #e72400;
}

.contenedor-Agrupadores {
  border-radius: 10px;
  border: solid;
  border-width: 1px;
  height: 540px;
}
.contenedor-Selectores-Reactivos {
  border-radius: 10px;
  border: solid;
  border-width: 1px;
  height: 540px;
}

.NewModal-Instrumento .gray-btn {
  background-color: #7e7d82;
  border: none;
  color: #fff;
  border-radius: 10px;
  width: 120px;
  padding: 6px 0;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal-Instrumento .gray-btn:hover {
  opacity: 0.75;
}

.NewModal-Instrumento .black-btn {
  background-color: #1b1c20;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal-Instrumento .delete-button {
  position: relative;
  float: left;
  background-color: #e72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal-Instrumento .black-btn:hover {
  opacity: 0.75;
}

.boton-agregar {
  padding: 0px !important;
  margin-top: 15px !important;
}

.NewModal-Instrumento label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.NewModal-Instrumento input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.NewModal-Instrumento textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.NewModal-Instrumento input::placeholder {
  font-family: 'Roboto';
}

.tabla-preguntas-instrumento {
  height: 230px !important;
  overflow-x: auto;
}

.tabla-preguntas-agrupador {
  height: 400px;
  overflow-x: auto;
}

.tabla-preguntas-instrumento > div {
  text-align: center;
  height: 50px;
  border: solid;
  border-width: 1px;
  width: 90%;
  margin-left: 5%;
}
.tabla-preguntas-instrumento-derecha {
  height: 400px !important;
  overflow-x: auto;
}
.tabla-preguntas-instrumento-derecha > div {
  text-align: center;
  min-height: 50px;
  border: solid;
  border-width: 1px;
  width: 90%;
  margin-left: 5%;
  display: flex;
}

.searchIconImage {
  height: 15px;
  position: absolute;
  margin-top: 30px;
  margin-left: 2px;
}
#to-search-tag {
  margin-top: 25px;
}

.to-widget {
  margin-top: 8px;
  cursor: pointer !important;
}
.parrafo-pregunta {
  border: solid !important;
}

.titulos-instrumento {
  text-align: center;
}

.titulos-instrumento h1 {
  margin: 0px;
}

.valor-pregunta {
  margin-top: 10px;
  margin-right: 5px !important;
}

.delete-confirm {
  text-align: center;
}

.arrow-icons-change-position {
  cursor: pointer;
}
.arrow-icons-change-position:hover {
  color: red;
}
#contenedor-previsualizacion {
  position: relative !important;
  height: 600px !important;
}
.contenedor-editor {
  height: 570px;
  border: solid;
  border-width: 1px;
  border-color: grey;
  overflow-x: auto;
}
.rdw-editor-main {
  height: 470px !important;
  overflow-x: none;
  margin-top: -5px;
}

.rdw-editor-main::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.rdw-editor-main::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.ver-html {
  position: relative;
  float: right;
  margin-top: 10px;
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  width: 80px;
  padding: 6px 0;
  cursor: pointer;
  z-index: 20;
}
.contenedor-html-descripcion {
  height: 562px;
  border: solid;
  border-width: 1px;
  border-color: grey;
  overflow-x: auto;
  width: calc(100% - 5px);
}

.rdw-emoji-wrapper {
  display: none !important;
}
.contenedor-nombre-reactivo {
  height: 45px;
  margin-top: 0px !important;
}
.contenedor-nombre-reactivo p {
  margin-top: 4px !important;
  height: 38px;
  overflow: hidden;
}
.contenedor-agrupador {
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  height: 300px;
  margin: 8px;
}
.contenedor-nombre-agrupador {
  border-left: solid;
  border-left-width: 1px;
  border-right: solid;
  border-right-width: 1px;
  text-align: center;
}
.header-agrupador {
  border-bottom-width: 1px !important;
  border-bottom: solid;
}

.check-aleatorio {
  height: 20px !important;
  margin-top: 15px;
}

.contenedor-aleatorio {
  float: left !important;
  display: inline-block !important;
}
