.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.first-header-item {
  border: 1px solid #000;
  font-weight: normal;
  font-size: 20px;
  padding: 5px;
}

.second-header-item {
  background-color: #ccc;
  font-weight: normal;
  padding: 8px;
}

.sub-header {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

/*
.campus-cell {
  text-align: left !important;
  padding-left: 20px;
}

*/
.table-participacion-global{
  overflow:auto;
  width: 100%;
  display: table;

}
.table-participacion-global thead{


}

.table-participacion-global thead tr{


}
.table-participacion-global thead .first-header-row th{
  top: 0;
  position: sticky;
  background-color: white;
}
.table-participacion-global tr td:first-child, .table-participacion-global tr th:first-child {
  width:200px;
  left: 0;
  position: sticky;
}
.extra-header{
  top:0;
  z-index:10!important;
  border-left:1px;
  border-bottom:1px;
  position:sticky;
}