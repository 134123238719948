.Actualizacion-Profesional {
  text-decoration: underline;
  color: grey;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
}
.estatus-actualizacion {
  color: red;
  font-weight: 600;
}
.registro-materia-resultados {
  padding: 0px;
  color: #333;
  font-weight: 600;
  cursor: pointer;
}
.registro-materia-resultados span {
  margin-left: 10px;
}
.registro-materia-resultados:hover {
  color: #000;
  font-weight: 1200;
  text-decoration: underline;
}
.contenedor-info-detalle-asignacion {
  background-color: rgb(238, 238, 238);
  width: 100%;
  height: 100px;
}
.contenedor-info-detalle-asignacion div {
  margin: 0px;
}
.contenedor-info-detalle-asignacion p {
  margin: 0px;
  margin-left: 10px;
  margin-top: 4px;
  font-weight: 900;
}
.contenedor-info-detalle-asignacion p span {
  color: grey;
}
.contenedor-header-detalle-asignacion span {
  margin-left: 30px;
  border: solid;
  padding-left: 10px;
  padding-right: 10px;
  border-width: 1px;
  border-color: grey;
}
.contenedor-reporte-detalle-asignacion {
  width: 95% !important;
  margin: auto;
  margin-top: 0px;
  height: calc(70% - 50px) !important;
  overflow: auto !important;
}

.contenedor-reporte-detalle-integral {
  width: 95% !important;
  margin: auto;
  margin-top: 0px;
  height: calc(70% - 50px) !important;
  overflow: auto !important;
}

.contenedor-reporte-detalle-asignacion::-webkit-scrollbar,
.contenedor-reporte-detalle-integral::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}
.contenedor-reporte-detalle-asignacion::-webkit-scrollbar-thumb,
.contenedor-reporte-detalle-integral::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.contenedor-header-detalle-integral {
  width: calc(100% - 5px) !important;
  font-weight: 900;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contenedor-header-detalle-integral .vertical-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  float: right;
  width: 30px;
}
.contenedor-header-detalle-integral p {
  background-color: #eeeeef;
}
.contenedor-header-detalle-integral
  .contenedor-numero-registro:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.5);
}
.contenedor-numero-registro {
  width: 30px;
  text-align: center;
}

.contenedor-titulo-registro {
  position: relative;
  float: right;
  margin-right: 0px !important;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: -5px !important;
  margin-bottom: 0px !important;
  padding: 5px;
}
.contenedor-headers-detalle-integral-p {
  margin-bottom: 40px !important;
  color: white !important;
  background-color: white !important;
  margin-right: 20px !important;
}
.contenedor-headers-detalle-integral-p span {
  color: black !important;
  background-color: white !important;
  text-align: left;
}
.contenedor-reporte-actualizacion-profesional {
  min-height: 180px;
  margin-top: 5px;
}
.contenedor-header-actualizacion-profesional {
  height: 40px;
}
.contenedor-reporte-actualizacion-profesional div::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.contenedor-reporte-actualizacion-profesional div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}

.section {
  background-color: #eee;
  padding: 23px;
  border-left: 7px solid red;
  margin-bottom: 10px;
}

.section input {
}

.input-respuesta {
  border: none;
  background-color: #fff;
  border-radius: 0;
  padding: 5px 10px;
  height: 22px;
  width: 100%;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lista-coordinadores {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  position: relative !important;
}
.lista-coordinadores span {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 18px;
}
.divisor-docentes {
  position: absolute;
  width: 75vw;
  height: 25px;
  left: 0px;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.1);
}

.respuesta-corta-divisor {
  height: 42px !important;
}
.coordinadores-respuesta-corta span {
  margin-top: 26px !important;
}
.respuestas {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
}
.respuestas label {
  margin-bottom: 20px;
}

.escala-seleccionada {
  background-color: red;
  width: calc(100% - 11px);
  height: 5px;
  position: absolute !important;
  margin-top: 20px;
  margin-left: calc(-50% - 0px);
  pointer-events: none;
}
.option-escala > div {
  position: relative;
  width: 100%;
  text-align: center !important;
}
.option-escala input {
  text-align: center;
}
.coordinadores-intervalo label {
  height: 38px;
}
.option-escala label {
  height: 38px;
  max-height: 38;
}
.contenedor-reporte-actualizacion-profesional th {
  text-transform: capitalize;
}
.formEvaluacionActual {
  margin: 0 10px;
  height: 90% !important;
}

.formEvaluacionActual label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.formEvaluacionActual input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 26px 5px 10px;
  border-radius: 15px;
  outline: none;
}

.formEvaluacionActual textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.formEvaluacionActual input::placeholder {
  font-family: 'Roboto';
}
.formEvaluacionActual {
  margin: 0 10px;
  height: 90% !important;
}

.formEvaluacionActual label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.formEvaluacionActual input {
  background-color: #ffffff;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.formEvaluacionActual textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.formEvaluacionActual input::placeholder {
  font-family: 'Roboto';
}
.formEvaluacionActual button {
  width: 90% !important;
  background-color: black;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 10px;
}
.formEvaluacionActual button:hover {
  background-color: grey;
}
.delete-reg-eval {
  background-color: black;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
}
.contenedorPadre-izquierdo {
  height: calc(100vh - 120px);
}
.contenedorPadre-izquierdo .sub-main {
  height: calc(50% - 12px);
  width: 99% !important;
}
.contenedorPadre-izquierdo .sub-contenedor {
  height: calc(100% - 50px);
  overflow: auto;
}

.contenedorPadre-derecho .subcontainer {
  height: calc(100vh - 180px) !important;
}
.contenedorPadre-derecho .sub-main {
  width: 98% !important;
}
.option input[type='radio'],
.option input[type='checkbox'] {
  width: 90%;
  cursor: pointer;
}
.option {
  width: 100%;
}
.section label {
  height: 40px;
}
.contenedor-reporte-actualizacion-profesional {
  overflow-x: auto;
}
.contenedor-reporte-actualizacion-profesional th {
  font-size: 15px !important;
}
.lista-coordinadores > label {
  text-transform: capitalize;
}
.custom-radio-checkbox {
  width: 100% !important;
  cursor: pointer;
  position: relative !important;
}
.custom-radio-checkbox:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.custom-radio-checkbox input {
  margin: auto;
  margin-top: 7px;
}

.resultados-detalle-integral {
  position: absolute;
  left: 30px;
  margin-top: -20px;
}
.resultados-detalle-integral p {
  background-color: white !important;
  font-size: 20px;
}
.resultados-detalle-integral p span {
  float: right;
  margin-left: 10px;

  text-align: center;
  border: solid;
  padding-left: 10px;
  padding-right: 10px;
  border-width: 1px;
  border-color: grey;
  width: 40px;
}
