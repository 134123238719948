.icon-edit-save-cell {
  position: absolute !important;
  right: 0px;
  top: 0px;
  cursor: pointer;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.icon-edit-save-cell svg {
  z-index: 0 !important;
  font-size: 20px;
  pointer-events: none;
}
div::-webkit-scrollbar {
  width: 5px;
  height: 10px !important;
  background-color: rgba(255, 255, 255, 0.2);
}

div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.select-to-selected-table {
  width: 260px !important;
}

.select-to-selected-table .select-search__value-container {
  max-height: 70px !important;
  overflow-y: auto;
}
.descargar-excel-selectedtable {
  position: fixed !important;
  top: 210px;
  left: 270px;
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  font-size: 20px;
}

.HeadResltado{
  background-color:yellow !important;
  box-shadow: inset 0px 0px 1px 2px orange;
  color:black !important
}
.ResaltarRow{
  border:solid;
  border-color:orange;
  border-top:none;
  border-width:1px;
  outline: 1px solid orange;
  color:black !important
}

.black-text {
  color: #000
}