.EditButton {
  background-color: #7e7d82;
  color: white;
  border: none;
  padding: 3px 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.EditButton:hover {
  opacity: 0.8;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 15px 5px 5px;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.5s;
  display: inline-flex;
  justify-content: center;
  align-self: center;
}

.NewButton span {
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 16px;
}

.NewButton:hover {
  opacity: 0.8;
}

.BlackButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 7px 10px;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.5s;
  display: inline-flex;
  justify-content: center;
  align-self: center;
}

.BlackButton span {
  margin-top: 4px;
  margin-left: 5px;
  font-size: 16px;
}

.BlackButton:hover {
  opacity: 0.8;
}

.ButtonCancel {
  background-color: #999999;
  cursor: not-allowed;
  pointer-events: none;
}
.Toggable-No {
  position: relative;
  background-color: #ccc;
  width: 50px;
  height: 22px;
  border: solid;
  border-color: grey;
  border-width: 1px;
  border-radius: 30px;
  cursor: pointer;
}
.Circle-Toggable-No {
  position: absolute;
  width: 10px;
  border: solid;
  height: 10px;
  left: 2px;
  top: 1px;
  border-radius: 10px;
  border-width: 5px;
  border-color: grey;
}
.Toggable-No span {
  position: absolute;
  right: 5px;
  top: 2px;
}
.Toggable-No:hover {
  border-color: #999;
}

.Toggable-Yes {
  position: relative;
  background-color: #f33;
  width: 50px;
  height: 22px;
  border: solid;
  border-color: red;
  border-width: 1px;
  border-radius: 30px;
  cursor: pointer;
  color: white;
}
.Circle-Toggable-Yes {
  position: absolute;
  width: 10px;
  border: solid;
  height: 10px;
  right: 2px;
  top: 1px;
  border-radius: 10px;
  border-width: 5px;
  border-color: white;
}
.Toggable-Yes span {
  position: absolute;
  left: 5px;
  top: 2px;
}
.Toggable-Yes:hover {
  border-color: red;
}
.button-helps{
  background: #d7282f !important;
  color: #fff;
  text-decoration: none;
  position: fixed;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  z-index: 98;
  bottom: 25px;
   font-size: 18px;
  padding: 10px 20px;
  border-radius: 30px;
  box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
  margin-bottom: 10px;
  right: 35px;
}
.button-svg{
  fill: #ffffff !important;
  transform: scale(1.2);
  margin: 3px 10px 0 0;
}