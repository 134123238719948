@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 8px !important;
  height: 10px !important;
  background-color: rgba(255, 255, 255, 0.2);
}


*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body > div > iframe {
  bottom:20px !important;
}
footer {
    background-color: #ddd;
    width: 100%;
    display: block;
    padding: 5px 20px;
    font-size: 13px;
    height: 25px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
}

footer span:first-child{
    float: left;
}

footer span:last-child{
    float: right
}
.EditButton {
  background-color: #7e7d82;
  color: white;
  border: none;
  padding: 3px 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.EditButton:hover {
  opacity: 0.8;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 15px 5px 5px;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.5s;
  display: inline-flex;
  justify-content: center;
  align-self: center;
}

.NewButton span {
  margin-top: 3px !important;
  margin-left: 5px !important;
  font-size: 16px;
}

.NewButton:hover {
  opacity: 0.8;
}

.BlackButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 7px 10px;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.5s;
  display: inline-flex;
  justify-content: center;
  align-self: center;
}

.BlackButton span {
  margin-top: 4px;
  margin-left: 5px;
  font-size: 16px;
}

.BlackButton:hover {
  opacity: 0.8;
}

.ButtonCancel {
  background-color: #999999;
  cursor: not-allowed;
  pointer-events: none;
}
.Toggable-No {
  position: relative;
  background-color: #ccc;
  width: 50px;
  height: 22px;
  border: solid;
  border-color: grey;
  border-width: 1px;
  border-radius: 30px;
  cursor: pointer;
}
.Circle-Toggable-No {
  position: absolute;
  width: 10px;
  border: solid;
  height: 10px;
  left: 2px;
  top: 1px;
  border-radius: 10px;
  border-width: 5px;
  border-color: grey;
}
.Toggable-No span {
  position: absolute;
  right: 5px;
  top: 2px;
}
.Toggable-No:hover {
  border-color: #999;
}

.Toggable-Yes {
  position: relative;
  background-color: #f33;
  width: 50px;
  height: 22px;
  border: solid;
  border-color: red;
  border-width: 1px;
  border-radius: 30px;
  cursor: pointer;
  color: white;
}
.Circle-Toggable-Yes {
  position: absolute;
  width: 10px;
  border: solid;
  height: 10px;
  right: 2px;
  top: 1px;
  border-radius: 10px;
  border-width: 5px;
  border-color: white;
}
.Toggable-Yes span {
  position: absolute;
  left: 5px;
  top: 2px;
}
.Toggable-Yes:hover {
  border-color: red;
}
.button-helps{
  background: #d7282f !important;
  color: #fff;
  text-decoration: none;
  position: fixed;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  z-index: 98;
  bottom: 25px;
   font-size: 18px;
  padding: 10px 20px;
  border-radius: 30px;
  box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
  margin-bottom: 10px;
  right: 35px;
}
.button-svg{
  fill: #ffffff !important;
  transform: scale(1.2);
  margin: 3px 10px 0 0;
}
.form-page {
  height: 100vh;
  width: 100vw;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image {
  max-width: 560px;
  width: 80vw;
}
.from {
  max-width: 380px;
  width: 80vw;
}
.input-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  margin: 30px 0;
}

.input {
  text-align: center;
  border-radius: 50px;
  border: none;
  height: 48px;
  width: 96%;
  display: block;
  font-size: 25px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.button {
  height: 50px;
  background-color: #0000;
  outline: none;
  border: none;
  cursor: pointer;
  padding-left: 14px;
}

.loader-container {
  padding-top: 3px;
  display: block;
}

.text {
  display: block;
}

.title {
  text-align: center;
  color: white;
  margin-top: -35px;
  font-size: 40px;
  margin-bottom: 0px;
}

.input-error {
  border-color: #a50000 !important;
}

.error-message {
  color: #a50000;
}

.MuiCircularProgress-colorPrimary {
  color: #a50000 !important;
}
.input-container {
  display: none;
}
.input-container select {
  display: none !important;
  text-align-last: center !important;
  border-radius: 50px !important;
  border: none !important;
  height: 48px !important;
  width: 100% !important;
  font-size: 25px !important;
  background-color: white !important;
  margin-bottom: -20px;
}

.link-ayuda img {
  width: 20px;
  position: absolute;
  margin-left: -30px;
}

.desplegador {
  width: 85%;
  text-align: right;
  padding-right: 25px;
  font-size: 30px;
  background: linear-gradient(to right, #c50200, red);
  margin: auto;
  margin-top: 10px;
  border-radius: 30px;
  color: white;
}
.desplegador span {
  float: left;
  margin-top: 3px;
  margin-left: 30px;
}
.contenido-desplegador {
  height: 360px;
  position: relative !important;
  width: 85%;
  margin: auto;
  margin-bottom: 20px !important;
  overflow: auto !important;
}
.SearchInput-despliegue {
  width: 200px !important;
  margin: 5px;
}
.contenedor-filtro-despliegue {
  text-align: right;
}
.desplegador-medio {
  width: 85%;
  text-align: right;
  padding-right: 25px;
  font-size: 20px;
  background: linear-gradient(to right, #c50200, red);
  margin: auto;
  margin-top: 3px;
  border-radius: 30px;
  color: white;
  height: 30px;
}

.view-title {
    width: 100%;
    height: 26px;
    background-color: #7E7D82;
    color: #fff;
    margin: 0;
    padding: 6px 20px;
    margin: 6px 30px;
    font-size: 1.3em;
    border-radius: 50px;
}
main {
  float: left;
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 110px);
  overflow: auto;
  width: calc(100% - 220px);
  position: relative;
  padding-top: 0px !important;
}

main .contenedor {
  width: 100%;
  height: calc(100vh - 180px);
  margin: 0 30px;
  overflow: scroll;
}

main .contenedor-padre {
  width: 100%;
  height: calc(100vh - 120px);
  position: relative !important;
}

.sub-main {
  float: left;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  width: 95%;
  position: relative !important;
  padding-top: 0px !important;
  border: solid;
  margin: 5px;
  border-width: 1px;
  border-radius: 10px;
  border-color: grey;
}
.sub-main .sub-contenedor {
  margin: 0px;
  width: 100%;
  height: calc(100% - 180px);
}
.sub-contenedor {
  position: relative !important;
  overflow: hidden;
}
.sub-contenedor div::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}
.sub-contenedor div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}

.NewModal {
  background-color: #fff;
  outline: 0;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
  position: relative;
}

.NewModal h3 {
  margin: 0;
  background-color: #1b1c20;
  color: #fff;
  padding: 10px 25px;
  font-size: 2em;
  font-weight: 400;
  border-bottom: 10px solid #e72400;
}

.NewModal-container2 {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  max-height: calc(100vh - 210px);
  position: relative;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.NewModal-footer {
  text-align: center;
  margin-top: auto;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.NewModal .gray-btn {
  background-color: #7e7d82;
  border: none;
  color: #fff;
  border-radius: 10px;
  width: 120px;
  padding: 6px 0;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .gray-btn:hover {
  opacity: 0.75;
}

.NewModal .black-btn {
  background-color: #1b1c20;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .delete-button {
  position: absolute;
  left: 30px;
  background-color: #e72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .black-btn:hover {
  opacity: 0.75;
}

.NewModal > svg {
  position: absolute;
  color: #ddd;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.NewModal > svg:hover {
  color: #fff;
}


.franja-titulo{
    -webkit-writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
    transform: rotate(180deg);
	color:white;
	background:linear-gradient(to right, #c50200, red);
	text-align:center;

	border-radius:0px 30px 30px 0px;
}
.cotenedor-franja{
	position:relative;
	margin-bottom:30px;
}
.contenedor-registros-franja p:nth-child(even) {
    background-color: #eeeeef;
}
.contenedor-registros-franja p{
	margin:0px;
	padding:5px;
	margin-left:10px;
	position:relative;
	padding-right:0px;
}
.contenedor-numero-registro{
	position:relative;
	float:right;
	margin-right:0px !important;
	background-color:rgba(0,0,0,0.1);
	margin-top:-5px !important;
	margin-bottom:0px !important;
	padding:5px;
}
.contenedor-numero-registro:nth-child(even){
	background-color:rgba(255,255,255,.5)
}
.table-dash thead tr th {
    background-color: #1B1C20;
    color: #fff;
    font-size: 1.25em;
    padding: 5px 10px;
    border-left: 2px solid #fff;
}

.table-dash tbody tr td {
    padding: 5px 10px;
    border-left: 2px solid #fff;
}

.table-dash tbody tr:nth-child(even) {
    background-color: #eeeeef;
}
.ultimaFila{
    background-color: #aeaeae;
}
.ultimaFila td{
    font-size: 18px;
    font-weight: 4000
}


.HeadArrows {
    position: absolute;
    top: 0;
    right: 0;
}

.HeadArrows svg {
    display: block;
}

.HeadArrows svg:nth-child(2) {
    margin-top: -15px;
}

.HeadCell {
    cursor: pointer;

}

.contenedor-buttons {
    text-align: center;
}

.contenedor-buttons button {
    border-top: #1a2f36 solid 2px;
    border-bottom: #1a2f36 solid 2px;
    border-left: #1a2f36 solid 2px;
    border-right: none;
    background-color: white;
    height: 40px;
    color: #1a2f36;
    padding: 0 10px;
    cursor: pointer;
    transition: all ease .5s;
    margin: 0;
}

.contenedor-buttons button:focus {
    outline: 0;
}

.contenedor-buttons button:hover {
    background-color: #1a2f36;
    color: white
}

.contenedor-buttons button:last-child {
    border-right: #1a2f36 solid 2px;
    border-radius: 0 10px 10px 0;
    padding: 0 15px;
}

.contenedor-buttons button:first-child {
    border-radius: 10px 0 0 10px;
    padding: 0 15px;
}

.contenedor-buttons button.active {
    background-color: #1a2f36;
    color: white;
}

th,td {
    text-align: center !important;
}
.doble-tabla tr{
    height: 70px;
}
.Actualizacion-Profesional {
  text-decoration: underline;
  color: grey;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
}
.estatus-actualizacion {
  color: red;
  font-weight: 600;
}
.registro-materia-resultados {
  padding: 0px;
  color: #333;
  font-weight: 600;
  cursor: pointer;
}
.registro-materia-resultados span {
  margin-left: 10px;
}
.registro-materia-resultados:hover {
  color: #000;
  font-weight: 1200;
  text-decoration: underline;
}
.contenedor-info-detalle-asignacion {
  background-color: rgb(238, 238, 238);
  width: 100%;
  height: 100px;
}
.contenedor-info-detalle-asignacion div {
  margin: 0px;
}
.contenedor-info-detalle-asignacion p {
  margin: 0px;
  margin-left: 10px;
  margin-top: 4px;
  font-weight: 900;
}
.contenedor-info-detalle-asignacion p span {
  color: grey;
}
.contenedor-header-detalle-asignacion span {
  margin-left: 30px;
  border: solid;
  padding-left: 10px;
  padding-right: 10px;
  border-width: 1px;
  border-color: grey;
}
.contenedor-reporte-detalle-asignacion {
  width: 95% !important;
  margin: auto;
  margin-top: 0px;
  height: calc(70% - 50px) !important;
  overflow: auto !important;
}

.contenedor-reporte-detalle-integral {
  width: 95% !important;
  margin: auto;
  margin-top: 0px;
  height: calc(70% - 50px) !important;
  overflow: auto !important;
}

.contenedor-reporte-detalle-asignacion::-webkit-scrollbar,
.contenedor-reporte-detalle-integral::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}
.contenedor-reporte-detalle-asignacion::-webkit-scrollbar-thumb,
.contenedor-reporte-detalle-integral::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.contenedor-header-detalle-integral {
  width: calc(100% - 5px) !important;
  font-weight: 900;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contenedor-header-detalle-integral .vertical-text {
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  transform: rotate(180deg);
  float: right;
  width: 30px;
}
.contenedor-header-detalle-integral p {
  background-color: #eeeeef;
}
.contenedor-header-detalle-integral
  .contenedor-numero-registro:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.5);
}
.contenedor-numero-registro {
  width: 30px;
  text-align: center;
}

.contenedor-titulo-registro {
  position: relative;
  float: right;
  margin-right: 0px !important;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: -5px !important;
  margin-bottom: 0px !important;
  padding: 5px;
}
.contenedor-headers-detalle-integral-p {
  margin-bottom: 40px !important;
  color: white !important;
  background-color: white !important;
  margin-right: 20px !important;
}
.contenedor-headers-detalle-integral-p span {
  color: black !important;
  background-color: white !important;
  text-align: left;
}
.contenedor-reporte-actualizacion-profesional {
  min-height: 180px;
  margin-top: 5px;
}
.contenedor-header-actualizacion-profesional {
  height: 40px;
}
.contenedor-reporte-actualizacion-profesional div::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.contenedor-reporte-actualizacion-profesional div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}

.section {
  background-color: #eee;
  padding: 23px;
  border-left: 7px solid red;
  margin-bottom: 10px;
}

.section input {
}

.input-respuesta {
  border: none;
  background-color: #fff;
  border-radius: 0;
  padding: 5px 10px;
  height: 22px;
  width: 100%;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lista-coordinadores {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 270px;
  position: relative !important;
}
.lista-coordinadores span {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 18px;
}
.divisor-docentes {
  position: absolute;
  width: 75vw;
  height: 25px;
  left: 0px;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.1);
}

.respuesta-corta-divisor {
  height: 42px !important;
}
.coordinadores-respuesta-corta span {
  margin-top: 26px !important;
}
.respuestas {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
}
.respuestas label {
  margin-bottom: 20px;
}

.escala-seleccionada {
  background-color: red;
  width: calc(100% - 11px);
  height: 5px;
  position: absolute !important;
  margin-top: 20px;
  margin-left: calc(-50% - 0px);
  pointer-events: none;
}
.option-escala > div {
  position: relative;
  width: 100%;
  text-align: center !important;
}
.option-escala input {
  text-align: center;
}
.coordinadores-intervalo label {
  height: 38px;
}
.option-escala label {
  height: 38px;
  max-height: 38;
}
.contenedor-reporte-actualizacion-profesional th {
  text-transform: capitalize;
}
.formEvaluacionActual {
  margin: 0 10px;
  height: 90% !important;
}

.formEvaluacionActual label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.formEvaluacionActual input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 26px 5px 10px;
  border-radius: 15px;
  outline: none;
}

.formEvaluacionActual textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.formEvaluacionActual input::-webkit-input-placeholder {
  font-family: 'Roboto';
}

.formEvaluacionActual input:-ms-input-placeholder {
  font-family: 'Roboto';
}

.formEvaluacionActual input::-ms-input-placeholder {
  font-family: 'Roboto';
}

.formEvaluacionActual input::placeholder {
  font-family: 'Roboto';
}
.formEvaluacionActual {
  margin: 0 10px;
  height: 90% !important;
}

.formEvaluacionActual label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.formEvaluacionActual input {
  background-color: #ffffff;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.formEvaluacionActual textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.formEvaluacionActual input::-webkit-input-placeholder {
  font-family: 'Roboto';
}

.formEvaluacionActual input:-ms-input-placeholder {
  font-family: 'Roboto';
}

.formEvaluacionActual input::-ms-input-placeholder {
  font-family: 'Roboto';
}

.formEvaluacionActual input::placeholder {
  font-family: 'Roboto';
}
.formEvaluacionActual button {
  width: 90% !important;
  background-color: black;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 10px;
}
.formEvaluacionActual button:hover {
  background-color: grey;
}
.delete-reg-eval {
  background-color: black;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
}
.contenedorPadre-izquierdo {
  height: calc(100vh - 120px);
}
.contenedorPadre-izquierdo .sub-main {
  height: calc(50% - 12px);
  width: 99% !important;
}
.contenedorPadre-izquierdo .sub-contenedor {
  height: calc(100% - 50px);
  overflow: auto;
}

.contenedorPadre-derecho .subcontainer {
  height: calc(100vh - 180px) !important;
}
.contenedorPadre-derecho .sub-main {
  width: 98% !important;
}
.option input[type='radio'],
.option input[type='checkbox'] {
  width: 90%;
  cursor: pointer;
}
.option {
  width: 100%;
}
.section label {
  height: 40px;
}
.contenedor-reporte-actualizacion-profesional {
  overflow-x: auto;
}
.contenedor-reporte-actualizacion-profesional th {
  font-size: 15px !important;
}
.lista-coordinadores > label {
  text-transform: capitalize;
}
.custom-radio-checkbox {
  width: 100% !important;
  cursor: pointer;
  position: relative !important;
}
.custom-radio-checkbox:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.custom-radio-checkbox input {
  margin: auto;
  margin-top: 7px;
}

.resultados-detalle-integral {
  position: absolute;
  left: 30px;
  margin-top: -20px;
}
.resultados-detalle-integral p {
  background-color: white !important;
  font-size: 20px;
}
.resultados-detalle-integral p span {
  float: right;
  margin-left: 10px;

  text-align: center;
  border: solid;
  padding-left: 10px;
  padding-right: 10px;
  border-width: 1px;
  border-color: grey;
  width: 40px;
}

.Actualizacion-Profesional {
  text-decoration: underline;
  color: grey;
  font-size: 20px;
  cursor: pointer;
}
.estatus-actualizacion {
  color: red;
}
.registro-materia-resultados {
  padding: 0px;
  color: #333;
  font-weight: 600;
  cursor: pointer;
}
.registro-materia-resultados span {
  margin-left: 10px;
}
.registro-materia-resultados:hover {
  color: #000;
  font-weight: 1200;
  text-decoration: underline;
}
.contenedor-info-detalle-asignacion {
  background-color: #efefef;
  width: 100%;
  height: 100px;
}
.contenedor-info-detalle-asignacion div {
  margin: 0px;
}
.contenedor-info-detalle-asignacion p {
  margin: 0px;
  margin-left: 10px;
  margin-top: 4px;
  font-weight: 900;
}
.contenedor-info-detalle-asignacion p span {
  color: #444;
}
.contenedor-header-detalle-asignacion span {
  margin-left: 30px;
  border: solid;
  padding-left: 10px;
  padding-right: 10px;
  border-width: 1px;
  border-color: grey;
}
.contenedor-reporte-detalle-asignacion {
  width: 100%;
  height: calc(70% - 80px);
  overflow: auto !important;
}

.contenedor-reporte-detalle-integral {
  width: 100%;
  height: calc(70% - 120px);
  overflow: auto !important;
}

.contenedor-reporte-detalle-asignacion::-webkit-scrollbar,
.contenedor-reporte-detalle-integral::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}
.contenedor-reporte-detalle-asignacion::-webkit-scrollbar-thumb,
.contenedor-reporte-detalle-integral::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.contenedor-header-detalle-integral {
  width: calc(100% - 5px) !important;
  font-weight: 900;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contenedor-header-detalle-integral .vertical-text {
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  transform: rotate(180deg);
  float: right;
}
.contenedor-header-detalle-integral p {
  background-color: #eeeeef;
}
.contenedor-header-detalle-integral
  .contenedor-numero-registro:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.5);
}
.contenedor-titulo-registro {
  position: relative;
  float: right;
  margin-right: 0px !important;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: -5px !important;
  margin-bottom: 0px !important;
  padding: 5px;
}
.contenedor-headers-detalle-integral-p {
  margin-bottom: 40px !important;
  color: white !important;
  background-color: white !important;
}
.contenedor-headers-detalle-integral-p span {
  color: black !important;
  background-color: white !important;
  text-align: left;
}
.contenedor-reporte-actualizacion-profesional {
  height: calc(100% - 200px);
}
.contenedor-header-actualizacion-profesional {
  height: 40px;
}
.contenedor-reporte-actualizacion-profesional div::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.contenedor-reporte-actualizacion-profesional div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}

.progress-bar {
  border: 1px solid gray;
  height: 15px;
  width: 300px;
  border-radius: 8px;
}

.progress {
  border-radius: 8px;
  background-image: linear-gradient(to right, #98192c, red);
  height: 100%;
}

.percent {
  color: grey;
  margin-left: 7px;
}

.section-title-container {
  height: 35px;
  background-color: #eee;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.section-title {
  font-size: 22px;
  margin-left: 15px;
}

.button-save {
  border: none;
  background-color: #636363;
  border-radius: 10px;
  padding: 1px 11px;
  color: #fff;
  cursor: pointer;
}

.input-busqueda {
  margin: 0px 25px 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.item-tabla {
  padding: 4px;
  font-weight: 600;
  text-align: left !important;
  padding-left: 15px;
}
.progress-bar-container{
  width:350px !important
}
.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.first-header-item {
  border: 1px solid #000;
  font-weight: normal;
  font-size: 20px;
  padding: 5px;
}

.second-header-item {
  background-color: #ccc;
  font-weight: normal;
  padding: 8px;
}

.sub-header {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
}

/*
.campus-cell {
  text-align: left !important;
  padding-left: 20px;
}

*/
.table-participacion-global{
  overflow:auto;
  width: 100%;
  display: table;

}
.table-participacion-global thead{


}

.table-participacion-global thead tr{


}
.table-participacion-global thead .first-header-row th{
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}
.table-participacion-global tr td:first-child, .table-participacion-global tr th:first-child {
  width:200px;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
}
.extra-header{
  top:0;
  z-index:10!important;
  border-left:1px;
  border-bottom:1px;
  position:-webkit-sticky;
  position:sticky;
}
.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.nuevoEtiquetaForm {
  margin: 0 10px;
}

.nuevoEtiquetaForm > div > div {
  margin-top: 25px;
}

.nuevoEtiquetaForm label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.nuevoEtiquetaForm input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.descripcion-dimension-textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  height: 78px;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.descripcion-dimension-textarea::-webkit-input-placeholder {
  font-family: 'Roboto';
}

.descripcion-dimension-textarea:-ms-input-placeholder {
  font-family: 'Roboto';
}

.descripcion-dimension-textarea::-ms-input-placeholder {
  font-family: 'Roboto';
}

.descripcion-dimension-textarea::placeholder {
  font-family: 'Roboto';
}
.nuevoDimensionForm {
  overflow-y: auto;
  overflow-x: hidden;
}

.contenedor-aceptar-opciones{
	position: fixed;
	width: 25%;
	max-width: 275px;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 3;
	max-height: 330px;
	text-align: center;
}
.contenedor-aceptar-opciones2-fromuser{
	position: fixed;
	width: 25%;
	max-width: 385px;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 3;
	max-height: 330px;
	text-align: center;
}
.contenedor-aceptar-opciones3{
	position: fixed;
	width: 25%;
	max-width: 14%;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 3;
	max-height: 330px;
	text-align: center;
}
.contenedor-aceptar-opciones4{
	position: fixed;
	width: 50%;
	max-width: 480px;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 3;
	max-height: 330px;
	text-align: center;
}
.div-contenedor-opciones{

	background-color: white;
	z-index: 3;
	max-height: 270px;
	overflow-y: auto;
	text-align: center;
}
.contenedor-aceptar-opciones4 .div-contenedor-opciones{
	text-align: left;
	font-size: 15px;
	padding-left: 3px;
}
.div-contenedor-opciones::-webkit-scrollbar{
    width: 5px;
    height: 5px !important;
    background-color: rgba(255,255,255,0.2);
}

.div-contenedor-opciones::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.6)
}
.button-aceptar-opciones{
    background-color: #1B1C20;
    border: none;
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Roboto';
    margin: 0 5px;
    width: 120px;
    padding: 6px 0;
    cursor: pointer;
    transition: all ease .5s;
  margin-top: 0px;
  z-index: 4;
}
.div-contenedor-opciones p{
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 5px;
	padding-bottom: 5px;
	cursor: pointer;
}

.div-contenedor-opciones p input{
	float: right;
	width: 30px;
}
.div-contenedor-opciones p:hover{
	background: #7E7D82
}

.principal-input-multiselect{
	text-align: center !important;
	cursor: default;
}

.arrow-down-select svg{
	position:absolute;
	margin-left: -40px;
	margin-top: -7px;
	font-size: 45px;
	color:#505050;
	pointer-events: none;
}

.contenedor-multiselect{
	position: relative;
}
#span-total-materias{
	position: absolute;
	left: 10px;
	top: 5px;
	font-size: 14px;
}
.principal-input-multiselect2{
	width: 96% !important;
	text-align: center;
	cursor: default;
}
.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.tabs-list {
  display: flex;
}
.NewModal-container > .tabs-list{
  
  margin-top: 10px;
}
.tab {
  border-radius: 20px 20px 0 0;
  padding: 5px 20px;
  cursor: pointer;
  transition: all ease 0.2s;
}

.tab:hover {
  z-index: 99;
}

.tab:nth-child(odd) {
  background-color: #b3b3b3;
}

.tab:nth-child(even){
  background-color: #1b1c20;
  color: #fff;
  margin-left: -10px;
}

.tab.active {
  background-color: #e72400;
  z-index: 9;
  color: white;
}

.tab-content {
  height: calc(100% - 30px);
  border-left: 1px solid #b3b3b3;
  border-right: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  padding: 0;
}
.tab-content-modal{
  height: calc(100% - 75px);
  border-left: 1px solid #b3b3b3;
  border-right: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  padding: 0;
}

.tab-header {
  height: 15px;
}

.icon-edit-save-cell {
  position: absolute !important;
  right: 0px;
  top: 0px;
  cursor: pointer;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.icon-edit-save-cell svg {
  z-index: 0 !important;
  font-size: 20px;
  pointer-events: none;
}
div::-webkit-scrollbar {
  width: 5px;
  height: 10px !important;
  background-color: rgba(255, 255, 255, 0.2);
}

div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.select-to-selected-table {
  width: 260px !important;
}

.select-to-selected-table .select-search__value-container {
  max-height: 70px !important;
  overflow-y: auto;
}
.descargar-excel-selectedtable {
  position: fixed !important;
  top: 210px;
  left: 270px;
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  font-size: 20px;
}

.HeadResltado{
  background-color:yellow !important;
  box-shadow: inset 0px 0px 1px 2px orange;
  color:black !important
}
.ResaltarRow{
  border:solid;
  border-color:orange;
  border-top:none;
  border-width:1px;
  outline: 1px solid orange;
  color:black !important
}

.black-text {
  color: #000
}
.contenedor-reporte-estructura {
  display: inline-block;
  height: calc(100% - 60px);
  width: 75%;
}
.contenedor-reporte-estructura th {
  min-width: 300px;
}

.aside-estructura {
  display: inline-block;
  height: calc(100% - 20px);
  width: 25%;
  position: relative;
}

.aside-title {
  margin-top: 5px;
  position: absolute;
  background: linear-gradient(to right, #c50200, red);
  color: white;
  width: 98%;
  font-size: 25px;
  text-align: center;
  border-radius: 30px;
  right: -15px;
  padding: 5px;
}
.sub-aside {
  position: absolute;
  margin-top: 50px;
  text-align: center;
  width: 100%;
}
.title-sub-aside {
  text-align: center;
  width: 100%;
  font-size: 20px;
  margin: 0px;
  margin-top: 20px;
}
.alert-aside {
  position: absolute;
  left: 10px;
  margin-top: -4px;
}
.aside-estructura p {
  margin: 7px;
}
.ver-mas-aside {
  background: linear-gradient(to right, #c50200, red);
  color: white;
  outline: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 3px;
}
.SearchInput::-webkit-input-placeholder {
  padding-left: 35% !important;
}
.SearchInput:-ms-input-placeholder {
  padding-left: 35% !important;
}
.SearchInput::-ms-input-placeholder {
  padding-left: 35% !important;
}
.SearchInput::placeholder {
  padding-left: 35% !important;
}

.SearchInput-participacion {
  position: absolute !important;
  width: 200px !important;
  right: 27%;
  margin-top: -35px;
  border: 2px solid #808080;
  border-radius: 13px;
  height: 30px;
}

.SearchInput-participacion::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}

.SearchInput-participacion:-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}

.SearchInput-participacion::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}

.SearchInput-participacion::placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-participacion::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-participacion::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}

.contenedor-pickers-filtro {
  position: absolute;
}
.contenedor-pickers-filtro input {
  border: solid;
  border-width: 1px;
  border-radius: 30px;
  border-color: grey;
  margin-left: 5px;
  margin-right: 5px;
}
.contenedor-pickers-filtro span {
  position: relative;
  top: 7px;
}
.contenedor-filtro-despliegue {
  margin-top: 5px;
  margin-bottom: 5px;
}
.SearchInput-tabla-completa {
  margin-right: 40px;
  padding-left: 5px;
  outline: none;
}
.opciones-bar {
  height: 50px;
}
.filtros-tabla-completa {
  position: absolute;
  top: 30px;
  left: 40px;
}
.filtros-tabla-completa p {
  margin-bottom: 0px;
}
.select-periodo {
  width: 200px;
}
thead,
th {
  z-index: 0 !important;
}
.SearchInput {
  height: 30px;
}
.filtros-tabla-completa .sub-filtro {
  display: inline-block;
  margin-left: 10px;
}
.sub-filtro .principal-input-multiselect {
  height: 33px;
  margin-top: 0px;
  border: solid;
  border-color: #bbb;
  border-radius: 5px;
  border-width: 1px;
  width: 275px;
  outline: none;
}
.contenedor-aceptar-opciones input {
  width: 80%;
}
.contenedor-loader-tabs-tabla {
  position: absolute;
  left: 35%;
}
th {
  z-index: 1 !important;
}

.selects-tabla-participacion-academica {
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: none;
  text-align: center !important;
  cursor: pointer;
}

.editar-catalogo-from {
  margin: 0 10px;
}

.editar-catalogo-from > div > div {
  margin-top: 25px;
}

.editar-catalogo-from label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.editar-catalogo-from input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
  margin-bottom: 10px;
}

.editar-catalogo-from textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.editar-catalogo-from input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-family: 'Roboto';
}

.editar-catalogo-from input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-family: 'Roboto';
}

.editar-catalogo-from input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-family: 'Roboto';
}

.editar-catalogo-from input::placeholder,
textarea::placeholder {
  font-family: 'Roboto';
}

.contenedor-opciones-descripcion-catalogo {
  height: 140px;
  overflow: auto;
  max-height: 240px;
  text-align: center;
  border: solid;
  border-width: 1px;
  border-color: #aaa;
  margin-bottom: -10px;
  padding: 10px;
  border-radius: 10px;
}

.contenedor-opciones-descripcion-catalogo button {
  border-radius: 10px;
  border: none;
  background-color: green;
  color: white;
  cursor: pointer;
  padding: 3px 6px 3px 6px;
  outline: none;
}
.contenedor-opciones-descripcion-catalogo button:disabled {
  background-color: grey;
  cursor: unset;
}
.contenedor-una-opcion {
  width: 98%;
  position: relative !important;
}
.eliminar-opcion-descripcion {
  position: absolute;
  right: -3px;
  top: -3px;
  background-color: white !important;
  color: black !important;
  padding: 0px !important;
}
.eliminar-opcion-descripcion:disabled {
  color: grey !important;
  cursor: unset;
}

.contenedor-reporte-estructura th {
  text-transform: capitalize;
}

.search-button {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  font-size: 20px;
  margin-left: 10px;
}

.contenedor-campus-avance-estructuras {
}
.contenedor-campus-avance-estructuras .contenedor-multiselect {
  width: 100% !important;
}
.contenedor-campus-avance-estructuras .contenedor-aceptar-opciones {
  width: 100% !important;
  max-width: 320px;
}
.SearchInput-AvanceEstrucutras {
  position: absolute !important;
  right: 40px;
  top: 60px;
  border: 2px solid #808080;
  border-radius: 13px;
  height: 30px;
  padding-left: 8px;
  outline: none;
}
.SearchInput-AvanceEstrucutras::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-AvanceEstrucutras:-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-AvanceEstrucutras::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-AvanceEstrucutras::placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-AvanceEstrucutras::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-AvanceEstrucutras::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}


.contenedor-aceptar-opciones2{
	width: 30% !important;
	max-width: 320px !important;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 3;
	max-height: 330px !important;
	text-align: center;
	position: absolute;
	width:320px !important;
}

.div-contenedor-opciones2{
	background-color: white;
	z-index: 3;
	max-height: 170px !important;
	overflow-y: auto;
	text-align: center;
}
.contenedor-aceptar-opciones4 .div-contenedor-opciones2{
	text-align: left;
	font-size: 15px;
	padding-left: 3px;
}
.div-contenedor-opciones2::-webkit-scrollbar{
    width: 5px;
    height: 5px !important;
    background-color: rgba(255,255,255,0.2);
}

.div-contenedor-opciones2::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.6)
}

.div-contenedor-opciones2 p{
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 5px;
	padding-bottom: 5px;
	cursor: pointer;
}

.div-contenedor-opciones2 p input{
	float: right;
	width: 30px;
}
.div-contenedor-opciones2 p:hover{
	background: #7E7D82
}

.div-contenedor-opciones2 .selected{
	background: #7E7D82
}


.principal-input-multiselect{
	text-align: center !important;
	cursor: default;
}

.arrow-down-select svg{
	position:absolute;
	margin-left: -40px;
	margin-top: -7px;
	font-size: 45px;
	color:#505050;
	pointer-events: none;
}

.contenedor-multiselect{
	position: relative;
}
#span-total-materias{
	position: absolute;
	left: 10px;
	top: 5px;
	font-size: 14px;
}
.principal-input-multiselect2{
	width: 96% !important;
	text-align: center;
	cursor: default;
}
.table-dash thead tr th {
    background-color: #1B1C20;
    color: #fff;
    font-size: 1.25em;
    padding: 5px 10px;
    border-left: 2px solid #fff;
}

.table-dash tbody tr td {
    padding: 5px 10px;
    border-left: 2px solid #fff;
}

.table-dash tbody tr:nth-child(even) {
    background-color: #eeeeef;
}
.ultimaFila{
    background-color: #aeaeae;
}
.ultimaFila td{
    font-size: 18px;
    font-weight: 4000
}


.HeadArrows {
    position: absolute;
    top: 0;
    right: 0;
}

.HeadArrows svg {
    display: block;
}

.HeadArrows svg:nth-child(2) {
    margin-top: -15px;
}

.HeadCell {
    cursor: pointer;

}

.contenedor-buttons {
    text-align: center;
}

.contenedor-buttons button {
    border-top: #1a2f36 solid 2px;
    border-bottom: #1a2f36 solid 2px;
    border-left: #1a2f36 solid 2px;
    border-right: none;
    background-color: white;
    height: 40px;
    color: #1a2f36;
    padding: 0 10px;
    cursor: pointer;
    transition: all ease .5s;
    margin: 0;
}

.contenedor-buttons button:focus {
    outline: 0;
}

.contenedor-buttons button:hover {
    background-color: #1a2f36;
    color: white
}

.contenedor-buttons button:last-child {
    border-right: #1a2f36 solid 2px;
    border-radius: 0 10px 10px 0;
    padding: 0 15px;
}

.contenedor-buttons button:first-child {
    border-radius: 10px 0 0 10px;
    padding: 0 15px;
}

.contenedor-buttons button.active {
    background-color: #1a2f36;
    color: white;
}

th,td {
    text-align: center !important;
}
.doble-tabla tr{
    height: 70px;
}
.header-edit-guardar{
    width:642px;
    margin-left:-1px !important;
    background-color:#1B1C20;
    margin-left:2px;
    color:white;
    height:35px;
    border-left:solid;
    border-right:solid;
    border-right-width:2px;
    border-top:solid;
    border-color:green;
}
.header-edit-guardar button{
    margin:10px;
    border:none;
    outline:none;
    border-radius:10px;
    padding:3px 6px 3px 6px;
    cursor:pointer;
    font-weight:600 !important;
}

.input-coordinador-draggable{
    border:none;
    margin:0px;
    width:99%;
    height:25px !important;
    text-align:center;
    background-color:rgba(0,0,0,0);
    outline:none;
    font-size:14px;
    color:black !important
}
.contenedor-opciones{
    display:none;
    background-color:white;
    border:solid;
    border-width:1px;
    border-color:grey;
    border-radius:10px;
    width:320px;
    max-height:300px;
    position:absolute;
    overflow:auto;
}

.contenedor-opciones::-webkit-scrollbar{
    width: 5px;
    height: 5px !important;
    background-color: rgba(255,255,255,0.2);
}

.contenedor-opciones::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.6)
}

.td-coordinador-draggable{
    padding-top:0px !important;
    padding-left:0px !important;
    padding-right:0px !important;
    padding-bottom:0px !important;
    border-left:solid !important;
    border-left-width:2px !important;
    border-left-color:green !important;
}
.td-coordinador-draggable-derecho{
    border-right:solid !important;
    border-right-width:2px !important;
    border-right-color:green !important;
}

.option-select-draggable{
    margin:0px;
    font-size:20px;
    cursor:pointer;
}
.option-select-draggable:hover{
    background-color:grey;
}
.left-header{
    border-left:solid !important;
    border-left-width:2px !important;
    border-left-color:black !important;
    border-left-color:green !important;
}
.right-header{
    border-right:solid !important;
    border-right-width:2px !important;
    border-right-color:black !important;
    border-right-color:green !important;
}
.selected-row{
    border-top:solid;
    border-bottom:solid !important;
    border-width:2px !important;
    border-color:green !important
}
.cuadrito-selector{
    width:10px;
    height:10px;
    background-color:green;
    position:absolute;
    right:0px;
    bottom:0px;
}
.progreso-estructuras2 {
  width: calc(100% - 880px) !important;
  position: absolute !important;
  top: 125px !important;
  left: 681px !important; 
}
.progreso-estructuras2 p {
  margin-bottom: 0px;
}
.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}
.contenedor-reporte-creado-estructuras2 {
  margin-top: 95px !important;
  height: calc(100% - 100px);
}
.contenedor-reporte-creado-estructuras2 th {
  min-width: 300px;
}
.contenedor-reporte-creado-estructuras2 .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}
.search-creado-estrucutras2 {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 98px;
  height: 30px;
  border: 2px solid #808080;
  border-radius: 13px;
}
.search-creado-estrucutras2::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.search-creado-estrucutras2:-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.search-creado-estrucutras2::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.search-creado-estrucutras2::placeholder {
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.search-creado-estrucutras2::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.search-creado-estrucutras2::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}
.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}
.circle-loading {
  width: 200px !important;
}

.small-bar {
  width: 110px;
}

.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.progreso-estructuras {
  width: calc(100% - 860px);
  position: absolute;
  top: 95px;
  left: 650px;
}

.progreso-estructuras p {
  margin-bottom: 0px;
}

.progreso-estrucutras .MuiLinearProgress-root {
  height: 300px !important;
}

.contenedor-reporte-creado-estructuras {
  margin-top: 70px;
  height: calc(100% - 100px);
}

.contenedor-reporte-creado-estructuras th {
  min-width: 300px;
}

.contenedor-reporte-creado-estructuras .header-sin-ancho {
  min-width: auto !important;
}

.send-button {
  width: 100px;
  background-color: black;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: pointer;
}

.search-creado-estrucutras {
  z-index: 2;
  position: absolute;
  right: -20px;
  margin-top: 70px;
}

.NewButton {
  background-color: #1b1c20;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-search__menu {
  z-index: 3 !important;
}

.ButtonXlsx {
  background-color: green;
  color: white;
  border: none;
  padding: 5px 20px;
  border-radius: 17px;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.ButtonXlsx:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.table-container {
  max-width: 90%;
  margin: 100px auto;
}

.button-download {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px;
  width: 130px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}

.button-massive-download {
  border: none;
  background-color: #ec2028;
  color: #fff;
  padding: 5px;
  width: 160px;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin-right: auto;
}

.input-busqueda {
  margin: 0px 0 20px;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 5px;
  width: 200px;
  text-align: center;
}

.itemTabla {
  padding: 4px;
  text-align: left !important;
  padding-left: 15px;
}

.nuevoEtiquetaForm {
  margin: 0 10px;
}

.nuevoEtiquetaForm > div > div {
  margin-top: 25px;
}

.nuevoEtiquetaForm label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.nuevoEtiquetaForm input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.descripcion-dimension-textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  height: 78px;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.descripcion-dimension-textarea::-webkit-input-placeholder {
  font-family: 'Roboto';
}

.descripcion-dimension-textarea:-ms-input-placeholder {
  font-family: 'Roboto';
}

.descripcion-dimension-textarea::-ms-input-placeholder {
  font-family: 'Roboto';
}

.descripcion-dimension-textarea::placeholder {
  font-family: 'Roboto';
}
.nuevoDimensionForm {
  overflow-y: auto;
  overflow-x: hidden;
}

.nuevoReactivoForm {
  margin: 0 10px;
  height: 90% !important;
}

.nuevoReactivoForm label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.nuevoReactivoForm input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 26px 5px 10px;
  border-radius: 15px;
  outline: none;
}

.nuevoReactivoForm textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.nuevoReactivoForm input::-webkit-input-placeholder {
  font-family: 'Roboto';
}

.nuevoReactivoForm input:-ms-input-placeholder {
  font-family: 'Roboto';
}

.nuevoReactivoForm input::-ms-input-placeholder {
  font-family: 'Roboto';
}

.nuevoReactivoForm input::placeholder {
  font-family: 'Roboto';
}
.editarReactivoForm {
  margin: 0 10px;
  height: 90% !important;
}

.editarReactivoForm label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.editarReactivoForm input {
  background-color: #ffffff;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.editarReactivoForm textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.editarReactivoForm input::-webkit-input-placeholder {
  font-family: 'Roboto';
}

.editarReactivoForm input:-ms-input-placeholder {
  font-family: 'Roboto';
}

.editarReactivoForm input::-ms-input-placeholder {
  font-family: 'Roboto';
}

.editarReactivoForm input::placeholder {
  font-family: 'Roboto';
}
#caja-pregunta {
  margin-bottom: 30px;
  margin-top: 30px;
}
.input-pregunta {
  background-color: white !important ;
  border: 0px solid #ccc !important  ;
  border-bottom: 1px solid #ccc !important   ;
  width: 100% !important ;
  box-sizing: border-box !important  ;
  padding: 5px 18px !important   ;
  border-radius: 0px !important  ;
  text-align: center !important  ;
  font-size: 20px !important  ;
  outline: none !important   ;
  height:none !important
}
.caja-opcion {
  margin-top: 10px;
}
.botones-radio {
  height: 20px !important;
}
.tag-valor {
  font-size: 16px !important;
}
.contenedor-respuestas {
  overflow-x: auto;
  height: 230px;
}
.caja-respuesta-corta {
  margin-top: 30px;
}
.button-delete-option {
  border-radius: 13px;
  border-color: #9f9fa2;
  border: solid;
  background-color: black;
  color: white;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

.caja-etiquetas {
  margin-top: 30px;
}
.caja-etiquetas input {
  margin-top: 0px !important;
}

.select-search-box > div > div > div > svg {
  display: none;
}
.select-search-box > div > div > span {
  display: none;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.select-search-box__option {
  padding-left: 0px;
  padding-right: 0px;
}

.NewModal {
  background-color: #fff;
  outline: 0;
  max-width: 800px;
  /* max-height: 78%; */
  width: 90%;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
  /* overflow-x: hidden; */
}

.NewModal h3 {
  margin: 0;
  background-color: #1b1c20;
  color: #fff;
  padding: 10px 25px;
  font-size: 2em;
  font-weight: 400;
  border-bottom: 10px solid #e72400;
}

.NewModal-container {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.NewModal-footer {
  position: relative;
  text-align: center;
  padding: 10px 0;
}

.NewModal .gray-btn {
  background-color: #7e7d82;
  border: none;
  color: #fff;
  border-radius: 10px;
  width: 120px;
  padding: 6px 0;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .gray-btn:hover {
  opacity: 0.75;
}

.NewModal .black-btn {
  background-color: #1b1c20;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .delete-button-modal,
.NewModal-Instrumento .delete-button-modal {
  position: absolute;
  left: 0px;
  background-color: #e72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .disabled-button {
  position: relative;
  float: left;
  background-color: grey;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
  cursor: not-allowed;
}

.figpo-button {
  position: absolute;
  right: 0px;
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 130px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.figpo-button-2 {
  position: absolute;
  right: 0px;
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 150px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.copiar-button {
  position: absolute;
  right: 20px;
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 210px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.close-modal {
  background-color: white;
  border-radius: 30px;
  position: relative;
  float: right;
  margin-right: -16px;
  cursor: pointer;
  color: grey;
  font-size: 20px;
  font-weight: 700;
}

.NewModal .black-btn:hover {
  opacity: 0.75;
}
.NewModal-Instrumento .NewModal-footer {
  position: relative !important;
}

.activa-container {
  position: absolute;
  left: 180px;
}

#contenedor-NuevoInstrumento {
  height: calc(100% - 40px);
  margin: 0 10px;
  margin-top: 10px;
}
.NewModal-Instrumento {
  background-color: #fff;
  outline: 0;
  max-width: 900px;
  width: 90%;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
}
.NewModal-Instrumento {
  background-color: #fff;
  outline: 0;
  max-width: 1250px;
  width: 90%;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
}

.NewModal-Instrumento h3 {
  margin: 0;
  background-color: #1b1c20;
  color: #fff;
  padding: 10px 25px;
  font-size: 2em;
  font-weight: 400;
  border-bottom: 10px solid #e72400;
}

.contenedor-Agrupadores {
  border-radius: 10px;
  border: solid;
  border-width: 1px;
  height: 540px;
}
.contenedor-Selectores-Reactivos {
  border-radius: 10px;
  border: solid;
  border-width: 1px;
  height: 540px;
}

.NewModal-Instrumento .gray-btn {
  background-color: #7e7d82;
  border: none;
  color: #fff;
  border-radius: 10px;
  width: 120px;
  padding: 6px 0;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal-Instrumento .gray-btn:hover {
  opacity: 0.75;
}

.NewModal-Instrumento .black-btn {
  background-color: #1b1c20;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal-Instrumento .delete-button {
  position: relative;
  float: left;
  background-color: #e72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal-Instrumento .black-btn:hover {
  opacity: 0.75;
}

.boton-agregar {
  padding: 0px !important;
  margin-top: 15px !important;
}

.NewModal-Instrumento label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.NewModal-Instrumento input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.NewModal-Instrumento textarea {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.NewModal-Instrumento input::-webkit-input-placeholder {
  font-family: 'Roboto';
}

.NewModal-Instrumento input:-ms-input-placeholder {
  font-family: 'Roboto';
}

.NewModal-Instrumento input::-ms-input-placeholder {
  font-family: 'Roboto';
}

.NewModal-Instrumento input::placeholder {
  font-family: 'Roboto';
}

.tabla-preguntas-instrumento {
  height: 230px !important;
  overflow-x: auto;
}

.tabla-preguntas-agrupador {
  height: 400px;
  overflow-x: auto;
}

.tabla-preguntas-instrumento > div {
  text-align: center;
  height: 50px;
  border: solid;
  border-width: 1px;
  width: 90%;
  margin-left: 5%;
}
.tabla-preguntas-instrumento-derecha {
  height: 400px !important;
  overflow-x: auto;
}
.tabla-preguntas-instrumento-derecha > div {
  text-align: center;
  min-height: 50px;
  border: solid;
  border-width: 1px;
  width: 90%;
  margin-left: 5%;
  display: flex;
}

.searchIconImage {
  height: 15px;
  position: absolute;
  margin-top: 30px;
  margin-left: 2px;
}
#to-search-tag {
  margin-top: 25px;
}

.to-widget {
  margin-top: 8px;
  cursor: pointer !important;
}
.parrafo-pregunta {
  border: solid !important;
}

.titulos-instrumento {
  text-align: center;
}

.titulos-instrumento h1 {
  margin: 0px;
}

.valor-pregunta {
  margin-top: 10px;
  margin-right: 5px !important;
}

.delete-confirm {
  text-align: center;
}

.arrow-icons-change-position {
  cursor: pointer;
}
.arrow-icons-change-position:hover {
  color: red;
}
#contenedor-previsualizacion {
  position: relative !important;
  height: 600px !important;
}
.contenedor-editor {
  height: 570px;
  border: solid;
  border-width: 1px;
  border-color: grey;
  overflow-x: auto;
}
.rdw-editor-main {
  height: 470px !important;
  overflow-x: none;
  margin-top: -5px;
}

.rdw-editor-main::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}

.rdw-editor-main::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.ver-html {
  position: relative;
  float: right;
  margin-top: 10px;
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  width: 80px;
  padding: 6px 0;
  cursor: pointer;
  z-index: 20;
}
.contenedor-html-descripcion {
  height: 562px;
  border: solid;
  border-width: 1px;
  border-color: grey;
  overflow-x: auto;
  width: calc(100% - 5px);
}

.rdw-emoji-wrapper {
  display: none !important;
}
.contenedor-nombre-reactivo {
  height: 45px;
  margin-top: 0px !important;
}
.contenedor-nombre-reactivo p {
  margin-top: 4px !important;
  height: 38px;
  overflow: hidden;
}
.contenedor-agrupador {
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  height: 300px;
  margin: 8px;
}
.contenedor-nombre-agrupador {
  border-left: solid;
  border-left-width: 1px;
  border-right: solid;
  border-right-width: 1px;
  text-align: center;
}
.header-agrupador {
  border-bottom-width: 1px !important;
  border-bottom: solid;
}

.check-aleatorio {
  height: 20px !important;
  margin-top: 15px;
}

.contenedor-aleatorio {
  float: left !important;
  display: inline-block !important;
}

.nuevoDimensionForm {
    margin: 0 10px;
    height: 95% !important;
}


.nuevoDimensionForm>div>div{
    margin-top: 25px;
}

.nuevoDimensionForm label {
    font-size: 13px;
    font-weight: 600;
    display: block;
    margin: 5px 15px;
}

.nuevoDimensionForm input {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 18px;
    border-radius: 15px;
    outline: none;
}

.nuevoDimensionForm select {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 0px;
    border-radius: 15px;
    outline: none;
}

.nuevoDimensionForm textarea {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    height: 78px;
    box-sizing: border-box;
    padding: 5px 18px;
    border-radius: 15px;
    outline: none;
}

.nuevoDimensionForm input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-family: 'Roboto';
}

.nuevoDimensionForm input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-family: 'Roboto';
}

.nuevoDimensionForm input::-ms-input-placeholder, textarea::-ms-input-placeholder {
    font-family: 'Roboto';
}

.nuevoDimensionForm input::placeholder,textarea::placeholder {
    font-family: 'Roboto';
}

.examinar-imagen button, .descripcion-dimension{
    width:50%;
    border:none;
    outline:none;
    cursor:pointer;
    font-size:20px;
    border-radius:30px;
    background-color:#1B1C20 !important;
    color:white !important
}
.examinar-imagen button:hover,.descripcion-dimension:hover{
    background-color:grey !important
}
.nuevoDimensionForm>div>div{
    margin-top:10px;
}
.inputs-tabla-escala{
    margin-top:-5px;
    margin-bottom:-5px;
}
.contenedor-escala100{
    border:solid !important;
    border-width:1px !important;
    border-radius:20px !important;
    height:60px;
    padding-left:20px;
    border-color:grey !important;
    margin-bottom:5px;
    position:relative !important;
}
.cuestionarios-container{
    height:300px;
}
.contenedor-rangos-escala100,.contenedor-rangos-escala100-instrumento{
    overflow:auto;
    width:98%;
    margin:auto;
    border:solid;
    border-width:1px;
    height:300px !important;
}

.contenedor-rangos-escala100::-webkit-scrollbar,.contenedor-rangos-escala100-instrumento::-webkit-scrollbar{
    width: 5px;
    height: 5px !important;
    background-color: rgba(255,255,255,0.2);
}

.contenedor-rangos-escala100::-webkit-scrollbar-thumb,.contenedor-rangos-escala100-instrumento::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.6)
}


.add-escala100-button{
    text-align:center;
    outline:none;
    cursor:pointer;
    background-color:green;
    border:none;
    font-size:16px;
    border-radius:10px;
    color:white;
}
.nuevoDimensionForm th{
    z-index:0 !important
}

.nuevoEtiquetaForm textarea {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    height: 78px;
    box-sizing: border-box;
    padding: 5px 18px;
    border-radius: 15px;
    outline: none;
}

.nuevoEtiquetaForm input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-family: 'Roboto';
}

.nuevoEtiquetaForm input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    font-family: 'Roboto';
}

.nuevoEtiquetaForm input::-ms-input-placeholder, textarea::-ms-input-placeholder {
    font-family: 'Roboto';
}

.nuevoEtiquetaForm input::placeholder,textarea::placeholder {
    font-family: 'Roboto';
}
.contenedor-estructura-booleana div{
    height:30px !important;
}
.contenedor-estructura-booleana{
    border:solid;
    border-width:1px;
    border-color:grey
}

.error{
    color:red !important
}
.eliminar-escala{
    position:absolute;
    background-color:white !important;
    border:none;
    outline:none;
    padding:0px;
    cursor:pointer;
    right:0px;
    top:19px;
    height:24px;
}
.eliminar-escala5{
    background-color:white !important;
    border:none;
    outline:none;
    padding:0px;
    cursor:pointer;
    height:24px;
}
.eliminar-escala5:hover{
    background-color:#cfcfcf !important;
}
.contenedor-nuevo-evaluacion-integral .input-evaluacion {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}
.contenedor-nuevo-evaluacion-integral > div {
  height: 100%;
}
.contenedor-nuevo-evaluacion-integral label {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 5px;
}

.contenedor-agrupadores-dimensiones {
  height: calc(100% - 120px);
  overflow: auto;
  text-align: center !important;
  border: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: grey;
}
.agrupador-dimension {
  height: 230px;
  width: 95% !important;
  margin: auto;
  margin-top: 10px;
  border: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: grey;
}

.agrupador-dimension h6 {
  background-color: black;
  color: white;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.contenedor-dimensiones {
  margin-top: -4px;
  height: 180px !important;
  overflow: auto;
}
.dimension-registro {
  height: 55px !important;
  width: 95%;
  margin: auto;
  border: solid;
  border-width: 1px;
  border-top: none;
}
.dimension-derecha {
  margin-bottom: 10px;
  border-radius: 10px;
}
.dimension-derecha div:first-child,
.dimension-derecha div:last-child {
  padding-top: 15px;
}

.dimension-derecha {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}
.dimension-derecha:active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
.Común-class {
  background-color: rgb(246, 190, 152);
  border-radius: 10px;
  color: white;
  font-weight: 900;
}
.Común-class {
  background-color: rgb(246, 190, 152);
  border-radius: 10px;
  color: white;
  font-weight: 900;
}
.Específica-class {
  background-color: rgb(161, 184, 225);
  border-radius: 10px;
  color: white;
  font-weight: 900;
}
.Extra-class {
  background-color: rgb(210, 210, 210);
  border-radius: 10px;
  color: white;
  font-weight: 900;
}
.Reconocimiento-class {
  background-color: rgb(210, 210, 210);
  border-radius: 10px;
  color: white;
  font-weight: 900;
}
.contenedor-Selectores-Reactivos-dimensiones,
.contenedor-Agrupadores2 {
  height: calc(100% - 50px) !important;
}
.NewModal {
  position: relative;
}
.tabla-preguntas-instrumento-derecha {
  overflow: auto !important;
}

.lista-dimensiones {
  height: 100%;
  overflow-x: auto;
}
.lista-dimensiones > div {
  text-align: center;
  min-height: 50px;
  border: solid;
  border-width: 1px;
  width: 90%;
  margin-left: 5%;
  display: flex;
}


.inputs-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.input-disabled {
  background-color: #dadada !important;
}

.opciones-bar {
  height: 60px !important;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.selector-fecha {
  width: 100%;
  height: 25px;
}

.selector-fecha > div {
  height: inherit;
}

.selector-fecha > div::before {
  content: none;
}

.selector-fecha > div::after {
  content: none;
}

.selector-fecha > div > input {
  height: inherit;
}

/* .nuevoEtiquetaForm__calendario {
  overflow-x: auto;
} */

.SearchInput-Calendario {
  position: absolute;
  right: 0px;
  margin-top: -30px;
  border: 2px solid #808080;
  border-radius: 13px;
  padding-left: 5px;
  outline: none;
}

.SearchInput-Calendario::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}

.SearchInput-Calendario:-ms-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}

.SearchInput-Calendario::-ms-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}

.SearchInput-Calendario::placeholder {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.SearchInput-Calendario::-webkit-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}
.SearchInput-Calendario::-ms-input-placeholder {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #999999;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}


.SearchInput-Usuarios {
  position: absolute;
  right: 0px;
  margin-top: -30px;
  border: 2px solid #808080;
  border-radius: 13px;
  height: 30px;
  outline: none;
}

.SearchInput-Usuarios::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}

.SearchInput-Usuarios:-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}

.SearchInput-Usuarios::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}

.SearchInput-Usuarios::placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-Usuarios::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-Usuarios::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}

.opciones-bar {
  height: 50px;
}

.nuevoUsuarioForm {
  display: flex;
  margin: 0 10px;
}

.nuevoUsuarioForm .avatarForm {
  width: 250px;
  text-align: center;
  position: relative;
}

.nuevoUsuarioForm label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.nuevoUsuarioForm input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.nuevoUsuarioForm input::-webkit-input-placeholder {
  font-family: 'Roboto';
}

.nuevoUsuarioForm input:-ms-input-placeholder {
  font-family: 'Roboto';
}

.nuevoUsuarioForm input::-ms-input-placeholder {
  font-family: 'Roboto';
}

.nuevoUsuarioForm input::placeholder {
  font-family: 'Roboto';
}

.avatarForm svg.avatar {
  height: 150px;
  width: 150px;
  border: 5px solid #1b1c20;
  border-radius: 100%;
  margin-top: 20px;
}

.editAvatar {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 140px;
  right: 20px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.editAvatar:hover {
  -webkit-filter: brightness(2.5);
          filter: brightness(2.5);
}

.select-search-box::after {
  width: 0px;
  height: 0px;
  border-top: 10px solid #777;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 10px;
  right: 8px;
  content: ' ';
}

.select-search-box__options {
  margin: 0;
  padding: 0;
}

.select-search-box {
  position: relative;
}

.select-search-box-rol > div {
  border-radius: 25px;
}

.select-search-box-rol > div > div > div > svg {
  display: none;
}
.select-search-box-rol > div > div > span {
  display: none;
}

.select-search-box--multiple {
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

input.select-search-box__search {
  line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
  box-shadow: none;
}

.select-search-box--input .select-search-box__search {
  cursor: text;
}

.select-search-box__search:focus {
  cursor: text;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

.select-search-box__select {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  overflow: auto;
  z-index: 100;
  min-height: 49px;
  border: 1px solid #ccc;
}

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}

.select-search-box__select--display {
  display: block;
}

.select-search-box__option {
  font-size: 14px;
  font-weight: 400;
  color: #616b74;
  padding: 5px 25px;
  border-top: 1px solid #eee;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.select-search-box__option:first-child {
  border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #f4f7fa;
}

.select-search-box__option--selected {
  background: #54a0ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #2184ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative;
}

.select-search-box__group-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}
.error {
  color: red;
}
#contenedor-lista-permisos button {
  position: relative;
  left: calc(50% - 180px) !important;
  width: 180px;
  background-color: #1b1c20;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: 'Roboto';
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}
#contenedor-lista-permisos label {
  margin-left: 10px;
  margin-top: 20px;
  text-transform: capitalize;
}
#contenedor-lista-permisos input {
  margin-left: 40px;
  height: 15px;
  width: 15px;
  padding: 0px;
  margin-top: 3px;
  font-size: 30px;
}

#contenedor-lista-permisos {
  overflow-x: auto;
  height: calc(100% - 13px);
}
#contenedor-lista-permisos::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}
#contenedor-lista-permisos::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.los-permisos {
  border-bottom: solid;
  border-width: 1px;
  border-collapse: all;
  text-transform: capitalize;
}

.permisos-contenerdor {
  width: 100%;
}

.permisos-contenerdor__info {
  margin-left: 10px;
}

.permisos-contenerdor__info_title {
  font-size: x-large;
}

.permisos-contenerdor__input {
  margin-bottom: 10px;
}
.contenedor-lista-permisos__button:focus {
  outline: none;
}

header {
  height: 80px;
  width: 100%;
  border-bottom: 10px solid #e72400;
}

header .logo {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  box-sizing: border-box;
  padding: 15px;
}

header .logo img {
  height: 80px;
}
@media screen and (max-width: 1150px) {
  header .logo img {
    height: 70px;
  }
}
@media screen and (max-width: 1000px) {
  header .logo img {
    height: 60px;
  }
}
@media screen and (max-width: 900px) {
  header .logo img {
    height: 50px;
  }
}

header .logo p {
  font-size: 20px;
  margin-left: 10px;
  border-left: 1px solid #e72400;
  height: 40px;
  padding-top: 15px;
  padding-left: 10px;
}

header .page-title h1 {
  text-transform: uppercase;
  font-size: 2.3em;
  padding-top: 18px;
  margin: 0;
  float: left;
  margin-left: 40px;
}
@media screen and (max-width: 1415px) {
  header .page-title h1 {
    font-size: 2em;
  }
}
@media screen and (max-width: 1300px) {
  header .page-title h1 {
    font-size: 1.8em;
  }
}
@media screen and (max-width: 1150px) {
  header .page-title h1 {
    font-size: 1.5em;
    padding-top: 25px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 1000px) {
  header .page-title h1 {
    font-size: 1.3em;
    padding-top: 30px;
    margin-left: 0px;
  }
}

header .user-info {
  float: right;
  display: flex;
  margin-top: 15px;
  margin-right: 25px;
  max-width: 400px;
}
@media screen and (max-width: 1415px) {
  header .user-info {
    max-width: 300px;
  }
}
@media screen and (max-width: 1150px) {
  header .user-info {
    max-width: 260px;
  }
}
@media screen and (max-width: 900px) {
  header .user-info {
    max-width: 200px;
  }
}

header .user-info > div {
  margin-right: 10px;
}

header .user-info .user-name {
  margin: 0;
  font-size: 1.5em;
  text-transform: uppercase;
  text-align: right;
}
@media screen and (max-width: 1415px) {
  header .user-info .user-name {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 1150px) {
  header .user-info .user-name {
    font-size: 1em;
    margin-top: 10px;
  }
}
@media screen and (max-width: 900px) {
  header .user-info .user-name {
    font-size: 0.8em;
  }
}

header .user-info .user-rol {
  margin: 0;
  font-size: 1em;
  text-align: right;
}

header .user-info svg {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
  transition: all ease 0.5s;
}

header .user-info svg:hover {
  opacity: 0.75;
}

header .user-info img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
  transition: all ease 0.5s;
  border: 2px solid #1b1c20;
}

header .user-info img:hover {
  opacity: 0.75;
}

aside {
  background-color: #1b1c20;
  width: 220px;
  height: calc(100vh - 110px);
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
}

aside::-webkit-scrollbar {
  width: 5px;
  height: 10px !important;
  background-color: rgba(255, 255, 255, 0.8);
}

aside::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

aside ul {
  padding: 0;
  margin: 0;
}

aside ul li a {
  font-size: 1.2em;
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px 0 10px 20px;
  transition: all ease 0.5s;
  padding-left: 50px;
}

aside ul li a svg {
  width: 20px;
  margin-right: 10px;
  position: absolute;
  left: 20px;
}

aside ul li:hover > a {
  background-color: #c6c6c5;
  color: #1b1c20;
  border-left: 5px solid #e72400;
}

aside ul li:hover > a svg path {
  fill: black;
}

aside ul li.active > a {
  background-color: #c6c6c5;
  color: #1b1c20;
  border-left: 5px solid #e72400;
}

aside ul li.active > a svg path {
  fill: black;
}

aside ul .menu-title {
  font-size: 1.75em;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 0 15px 20px;
}

aside ul .menu-title svg {
  width: 25px;
  margin-right: 10px;
}

.reportes-tab {
  background-color: rgb(0, 0, 0);
  width: 220px;
  right: 0;
  z-index: 999;
  box-shadow: 0px 0px 22px -11px rgba(0, 0, 0, 0.42);
}

.reportes-tab a {
  font-size: 16px;
  display: block;
  padding: 6px 8px;
  margin-left: 20px;
}

.reportes-tab a:hover {
  background-color: #333;
  border-left: 5px solid #e72400;
}

.reportes-tab a.active {
  background-color: #333;
  border-left: 5px solid #e72400;
}

