.contenedor-campus-avance-estructuras {
}
.contenedor-campus-avance-estructuras .contenedor-multiselect {
  width: 100% !important;
}
.contenedor-campus-avance-estructuras .contenedor-aceptar-opciones {
  width: 100% !important;
  max-width: 320px;
}
.SearchInput-AvanceEstrucutras {
  position: absolute !important;
  right: 40px;
  top: 60px;
  border: 2px solid #808080;
  border-radius: 13px;
  height: 30px;
  padding-left: 8px;
  outline: none;
}
.SearchInput-AvanceEstrucutras::placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-AvanceEstrucutras::-webkit-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
.SearchInput-AvanceEstrucutras::-ms-input-placeholder {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  color: #999999;
}
