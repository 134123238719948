main {
  float: left;
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 110px);
  overflow: auto;
  width: calc(100% - 220px);
  position: relative;
  padding-top: 0px !important;
}

main .contenedor {
  width: 100%;
  height: calc(100vh - 180px);
  margin: 0 30px;
  overflow: scroll;
}

main .contenedor-padre {
  width: 100%;
  height: calc(100vh - 120px);
  position: relative !important;
}

.sub-main {
  float: left;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  width: 95%;
  position: relative !important;
  padding-top: 0px !important;
  border: solid;
  margin: 5px;
  border-width: 1px;
  border-radius: 10px;
  border-color: grey;
}
.sub-main .sub-contenedor {
  margin: 0px;
  width: 100%;
  height: calc(100% - 180px);
}
.sub-contenedor {
  position: relative !important;
  overflow: hidden;
}
.sub-contenedor div::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}
.sub-contenedor div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
