@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 8px !important;
  height: 10px !important;
  background-color: rgba(255, 255, 255, 0.2);
}


*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body > div > iframe {
  bottom:20px !important;
}