.form-page {
  height: 100vh;
  width: 100vw;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image {
  max-width: 560px;
  width: 80vw;
}
.from {
  max-width: 380px;
  width: 80vw;
}
.input-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  margin: 30px 0;
}

.input {
  text-align: center;
  border-radius: 50px;
  border: none;
  height: 48px;
  width: 96%;
  display: block;
  font-size: 25px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.button {
  height: 50px;
  background-color: #0000;
  outline: none;
  border: none;
  cursor: pointer;
  padding-left: 14px;
}

.loader-container {
  padding-top: 3px;
  display: block;
}

.text {
  display: block;
}

.title {
  text-align: center;
  color: white;
  margin-top: -35px;
  font-size: 40px;
  margin-bottom: 0px;
}

.input-error {
  border-color: #a50000 !important;
}

.error-message {
  color: #a50000;
}

.MuiCircularProgress-colorPrimary {
  color: #a50000 !important;
}
.input-container {
  display: none;
}
.input-container select {
  display: none !important;
  text-align-last: center !important;
  border-radius: 50px !important;
  border: none !important;
  height: 48px !important;
  width: 100% !important;
  font-size: 25px !important;
  background-color: white !important;
  margin-bottom: -20px;
}

.link-ayuda img {
  width: 20px;
  position: absolute;
  margin-left: -30px;
}
