.desplegador {
  width: 85%;
  text-align: right;
  padding-right: 25px;
  font-size: 30px;
  background: linear-gradient(to right, #c50200, red);
  margin: auto;
  margin-top: 10px;
  border-radius: 30px;
  color: white;
}
.desplegador span {
  float: left;
  margin-top: 3px;
  margin-left: 30px;
}
.contenido-desplegador {
  height: 360px;
  position: relative !important;
  width: 85%;
  margin: auto;
  margin-bottom: 20px !important;
  overflow: auto !important;
}
.SearchInput-despliegue {
  width: 200px !important;
  margin: 5px;
}
.contenedor-filtro-despliegue {
  text-align: right;
}
.desplegador-medio {
  width: 85%;
  text-align: right;
  padding-right: 25px;
  font-size: 20px;
  background: linear-gradient(to right, #c50200, red);
  margin: auto;
  margin-top: 3px;
  border-radius: 30px;
  color: white;
  height: 30px;
}
